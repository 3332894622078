import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { groupByDate } from "../../../../../utils/utils";
import Session from "./Session";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function SessionsList({ sessions, handleSessionClick }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const sessionsGroupedByDate = groupByDate(sessions);
  return (
    <Box>
      {sessionsGroupedByDate.map((sessionGroupedByDate, i) => {
        const { date, items } = sessionGroupedByDate;

        return (
          <Box key={date} mb={i === sessionsGroupedByDate.length - 1 ? 0 : 3}>
            <Box px={2} pb={1}>
              <Typography variant="overline">
                <span className={classes.gradientText}>{date}</span>
              </Typography>
            </Box>
            <Box
              borderRadius={3}
              border={1}
              borderColor={theme.palette.divider}>
              {items.map((session, j) => {
                return (
                  <Session
                    key={session["id"]}
                    session={session}
                    numOfItems={items.length}
                    index={j}
                    handleSessionClick={handleSessionClick}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default SessionsList;
