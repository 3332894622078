import React from "react";
import { Box, Avatar } from "@mui/material";
import AnswerEntity from "./AnswerEntity";
import StreamAnswer from "./StreamAnswer";
import AnswerAvatar from "./AnswerAvatar";
import TabularDataAnswer from "./TabularDataAnswer";

function Answer({
  source,
  answer,
  handleEntityChange = () => {},
  handleSourceChange = () => {},
}) {
  const isStreamApiFail =
    answer["error"] && answer["error"] === "stream_api_fail";
  const answerType = answer["content_object"]["type"];
  const isAnswerFound = answer["content_object"]["answer_found"];

  function AnswerBasedOnType() {
    if (answerType === "entity") {
      return (
        <AnswerEntity
          source={source}
          answer={answer}
          handleEntityChange={handleEntityChange}
        />
      );
    } else if (answerType === "tabular_data") {
      return (
        <TabularDataAnswer
          source={source}
          answer={answer}
          handleSourceChange={handleSourceChange}
        />
      );
    }
    return <StreamAnswer answer={answer} />;
  }

  return (
    <Box
      display={"flex"}
      alignItems={"start"}
      pt={
        answerType === "entity" ||
        (!isAnswerFound && !answerType) ||
        isStreamApiFail
          ? 3.5
          : 0
      }>
      <AnswerAvatar />
      <AnswerBasedOnType />
    </Box>
  );
}

export default Answer;
