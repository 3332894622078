import React, { useState } from "react";
import { Box, Chip, Typography, TextField, useTheme } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";

function Filters({ sessions, handleQueryChange }) {
  const theme = useTheme();
  const [query, setQuery] = useState("");

  const handleQuery = (e) => {
    const query = e.target.value;
    setQuery(query);
    handleQueryChange(query);
  };

  return (
    <Box pl={2} py={1} display="flex" alignItems="center" justifyContent={"space-between"}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">Recent Sessions</Typography>
        <Chip
          label={`${sessions.length} session${sessions.length > 1 ? "s" : ""}`}
          size="small"
          sx={{
            ml: 1,
            color: theme.palette.text.secondary,
            background: theme.palette.background.light,
          }}
        />
      </Box>
      <TextField
        variant="outlined"
        placeholder="Search sessions"
        size={"small"}
        value={query}
        onChange={handleQuery}
        InputProps={{
          startAdornment: <SearchRounded sx={{ mr: 1, color: theme.palette.action.active }} />,
          style: { borderRadius: 8 },
        }}
      />
    </Box>
  );
}

export default Filters;
