import React from "react";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { getSessionItemDate } from "../../../../../utils/utils";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { EditRounded } from "@mui/icons-material";
import EditSession from "../../../components/EditSession";
import { DomainChip } from "../../../../Files/FilesView/Results/FilesList/GridView";

const useStyles = makeStyles()((theme, _params, classes) => ({
  session: {
    padding: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
      [`& .${classes.actions}`]: {
        visibility: "visible",
      },
    },
  },
  actions: {
    visibility: "hidden",
    alignItems: "center",
    padding: 4,
    borderRadius: 4,
    background: "#424242",
  },
  actionIcon: {
    color: "white",
    cursor: "pointer",
  },
}));

function Session({ session, numOfItems, index, handleSessionClick }) {
  const { id, title, updated_at } = session;
  const isLast = index === numOfItems - 1;
  const isFirst = index === 0;
  const { classes } = useStyles();

  function getBorderRadius() {
    if (numOfItems === 1) {
      return "12px";
    }
    if (isFirst) {
      return "12px 12px 0px 0px";
    }
    if (isLast) {
      return "0px 0px 12px 12px";
    }
    return 0;
  }

  return (
    <Box>
      <Box
        sx={{
          borderRadius: getBorderRadius(),
        }}
        className={classes.session}
        onClick={() => handleSessionClick(id)}>
        <Box width={{ xs: "calc(100% - 64px)", sm: "100%" }}>
          <Typography fontWeight={500} noWrap>
            {title}
          </Typography>
          <Box mb={0.5} />
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="caption" color={"textSecondary"}>
              {getSessionItemDate(updated_at)}
            </Typography>
          </Box>
        </Box>
        <Box mr={3} />
        <Box className={classes.actions} onClick={(e) => e.stopPropagation()}>
          <EditSession session={session}>
            <Tooltip title={"Edit session name"}>
              <EditRounded className={classes.actionIcon} fontSize="small" />
            </Tooltip>
          </EditSession>
        </Box>
        <Box mr={3} />
        <ArrowForwardIosRounded fontSize="small" htmlColor="gray" />
      </Box>
      {!isLast && <Divider sx={{ ml: 2 }} />}
    </Box>
  );
}

export default Session;
