import React from "react";
import { Box } from "@mui/material";

function ImageViewer({ file }) {
  const { name, file_url } = file;
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <img src={file_url} alt={name} style={{ maxWidth: 900 }} />
    </Box>
  );
}

export default ImageViewer;
