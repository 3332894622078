import React, { useState, useRef } from "react";
import { Box, useTheme, Typography, Chip, Tooltip } from "@mui/material";
import { formatDate } from "../../../../../utils/utils";
import FileActionsMenu from "./FilesActionMenu";
import { getFileIconImage } from "./GridView";
import useIsOverflow from "../../../../../components/useIsOverflow";
import { RotateLeftRounded } from "@mui/icons-material";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import { notSupportedBrowserFiles } from "./GridView";

function ListView({
  files,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
      }}>
      {files.map((file, i) => {
        const { id } = file;
        return (
          <React.Fragment key={id}>
            <ListViewItem
              file={file}
              handleSelectFile={handleSelectFile}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
}

function ListViewItem({
  file,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const theme = useTheme();
  const { name, created_at, file_url, file_type, status } = file;
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  //if file is completed or not
  let isFileCompleted = status === "completed";

  const handleOpenFileInNewTab = () => {
    if (notSupportedBrowserFiles.includes(file_type)) {
      window.open(file_url, "_blank", "noopener,noreferrer");
    } else {
      handleSelectFile(file, true);
    }
  };

  const handleClickFile = () => {
    if (file_type === "docx") {
      setIsConfirmationDialogOpen(true);
    } else {
      handleSelectFile(file, false);
    }
  };

  const handleClickYes = () => {
    handleSelectFile(file);
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      <Box
        onClick={handleClickFile}
        elevation={0}
        sx={{
          width: "100%",
          cursor: isFileCompleted ? "pointer" : "default",
          "&:hover": {
            backgroundColor: isFileCompleted
              ? theme.palette.action.hover
              : theme.palette.background.light,
          },
          borderBottom: `1px solid ${theme.palette.divider}`,
          "&:last-child": {
            borderBottom: "none",
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          },
          "&:first-child": {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
          backgroundColor: isFileCompleted
            ? "white"
            : theme.palette.background.light,
          // borderRadius: "inherit",
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
          }}>
          <Box
            maxWidth={300}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}>
            <img
              src={getFileIconImage(file_type)}
              alt="file_icon"
              width={30}
              height={30}
              style={{ opacity: isFileCompleted ? 1 : 0.5 }}
            />
            <Box mr={1} />
            <Box display={"flex"} flexDirection={"column"} ml={1}>
              <Box display={"flex"} alignItems={"center"}>
                <Tooltip title={name} arrow disableHoverListener={!isOverflow}>
                  <Typography
                    ref={ref}
                    variant="subtitle2"
                    fontWeight={400}
                    sx={{
                      letterSpacing: "0.1px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: 450,
                    }}>
                    {name}
                  </Typography>
                </Tooltip>
                {!isFileCompleted && (
                  <Chip
                    label="Processing..."
                    size="small"
                    variant="filled"
                    sx={{
                      backgroundColor: "var(--orange-lighten-5, #FFF3E0);",
                      color: "var(--orange-darken-4, #E65100)",
                      "& .MuiChip-icon": {
                        color: "var(--orange-darken-4, #E65100)",
                      },
                      ml: 2,
                    }}
                    icon={<RotateLeftRounded />}
                  />
                )}
              </Box>
              <Typography variant="caption" color={"textSecondary"} mt={0.5}>
                {formatDate(created_at)}
              </Typography>
            </Box>
          </Box>
          <Box onClick={(e) => e.stopPropagation()}>
            <FileActionsMenu
              file={file}
              edge={null}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
              handleOpenFileInNewTab={handleOpenFileInNewTab}
            />
          </Box>
        </Box>
      </Box>
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          title={"Download and open file?"}
          content={
            "This file cannot be opened in the browser. Do you want to download and open in your system?"
          }
          onNo={() => setIsConfirmationDialogOpen(false)}
          onYes={handleClickYes}
        />
      )}
    </>
  );
}

export default ListView;
