import React, { useState } from "react";
import Header from "./Header";
import CSVViewer from "../CSVViewer";
import { Box } from "@mui/material";

function ExcelViewer({
  file,
  handleDeleteFile,
  handleUpdateFile,
  handleGoBack,
}) {
  const { csv_file_map } = file;
  const csvList = Object.keys(csv_file_map);
  const [selectedCSV, setSelectedCSV] = useState(
    csvList.length > 0 ? csvList[0] : null
  );

  const handleChangeCSV = (csv) => {
    setSelectedCSV(csv);
  };

  return (
    <>
      <Header
        file={file}
        selectedCSV={selectedCSV}
        handleChangeCSV={handleChangeCSV}
        handleGoBack={handleGoBack}
        handleDeleteFile={handleDeleteFile}
        handleUpdateFile={handleUpdateFile}
      />
      <Box
        flex={1}
        overflow={"auto"}
        sx={{
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
        position={"relative"}>
        {selectedCSV && (
          <CSVViewer file={{ file_url: csv_file_map[selectedCSV] }} />
        )}
      </Box>
    </>
  );
}

export default ExcelViewer;
