import React, { Fragment } from "react";
import {
  ChatRounded,
  EditNoteRounded,
  ImageSearchRounded,
  SnippetFolderRounded,
  TimelineRounded,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CHECKLIST_DIAGRAM_MODE_URL,
  DATA_ANALYSIS_MODE_URL,
  FILES_URL,
  Q_A_MODE_URL,
  SESSIONS_URL,
} from "../../../utils/routes";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { GradientIconLight } from "../../../components/GradientIcon";
import { useDispatch } from "react-redux";
import {
  getSessionMessagesSuccess,
  getSessionsSuccess,
} from "../../../core/events/sessionEvents";

const useStyles = makeStyles()((theme, { expanded }, classes) => ({
  activeNavItem: {
    background: expanded ? theme.palette.primary.lightBg : "none",
    [`& .${classes.labelText}`]: {
      fontWeight: 500,
      background: theme.palette.light,
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    [`& .${classes.listItemIcon}`]: {
      background: !expanded ? theme.palette.primary.lightBg : "none",
    },
  },
  collapsedMenu: {
    flexDirection: "column",
    paddingLeft: 0,
    paddingRight: 0,
    [`& .${classes.listItemIcon}`]: {
      minWidth: 24,
      padding: 4,
      borderRadius: 6,
    },
    [`& .${classes.label}`]: {
      fontSize: 11,
      width: 48,
      textOverflow: "ellipsis",
      textAlign: "center",
    },
  },
  label: {
    fontSize: 12,
  },
  labelText: {},
  listItemIcon: {},
}));

const navItems = [
  [
    { label: "QnA", icon: ChatRounded, path: Q_A_MODE_URL },
    {
      label: "Checklist/Diagram",
      icon: ImageSearchRounded,
      path: CHECKLIST_DIAGRAM_MODE_URL,
    },
    {
      label: "Data Analysis",
      icon: TimelineRounded,
      path: DATA_ANALYSIS_MODE_URL,
    },
    // { label: "Writer", icon: EditNoteRounded, path: SESSIONS_URL },
  ],
  [{ label: "Files", icon: SnippetFolderRounded, path: FILES_URL }],
];

function NavItems({ expanded }) {
  const { classes } = useStyles({ expanded });
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClickNavItem = (navItem) => {
    const { path } = navItem;
    history.push(path);
    if (!location.pathname.startsWith(path)) {
      dispatch(getSessionsSuccess([]));
      dispatch(getSessionMessagesSuccess([]));
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} flex={1} overflow={"scroll"}>
      {navItems.map((items, i) => {
        return (
          <Fragment key={i}>
            <Box px={1.5}>
              {items.map((navItem) => {
                const { label, icon, path } = navItem;
                const Icon = icon;
                const isSelected = location.pathname.startsWith(path);
                return (
                  <Tooltip
                    key={label}
                    title={label}
                    arrow
                    disableHoverListener={expanded}>
                    <MenuItem
                      key={label}
                      className={`${
                        isSelected ? classes.activeNavItem : null
                      } ${!expanded ? classes.collapsedMenu : null}`}
                      sx={{ padding: 1, borderRadius: 1 }}
                      onClick={() => handleClickNavItem(navItem)}>
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        {isSelected ? (
                          <GradientIconLight Icon={icon} />
                        ) : (
                          <Icon />
                        )}
                      </ListItemIcon>
                      <Typography
                        className={classes.label}
                        noWrap
                        color="textSecondary">
                        <span className={classes.labelText}>{label}</span>
                      </Typography>
                    </MenuItem>
                  </Tooltip>
                );
              })}
            </Box>
            {i !== navItems.length - 1 && <Divider sx={{ my: 1 }} />}
          </Fragment>
        );
      })}
    </Box>
  );
}

export default NavItems;
