const API_BASE_URL =
  process.env.REACT_APP_ENV === "prod"
    ? "https://api.ravenapp.dev/copilot"
    : "https://api.staging.ravenapp.dev/copilot";

export const isProd = process.env.REACT_APP_ENV === "prod";

//Ask AI
export const CHAT_URL = API_BASE_URL + "/ask_ai_stream";
export const CHAT_METADATA_URL = API_BASE_URL + "/ask_ai/metadata";

//Account
export const GET_ACCOUNTS_URL = API_BASE_URL + "/v1/accounts?key=%s";
export const SEND_MAGIC_LINK_URL = API_BASE_URL + "/v1/send_magic_link";
export const AUTHENTICATE_MAGIC_LINK_URL =
  API_BASE_URL + "/v1/authenticate_magic_link";
export const REVOKE_SESSION_URL = API_BASE_URL + "/v1/revoke_session";
export const UPGRADE_REQUEST_URL = API_BASE_URL + "/v1/upgrade_request";
export const GET_USER_AND_ACCOUNT_URL = API_BASE_URL + "/v1/users";
export const UPDATE_USERNAME_URL = API_BASE_URL + "/v1/user";

//sessions
export const CREATE_SESSION_URL = API_BASE_URL + "/v1/conversations";
export const UPDATE_SESSION_URL = API_BASE_URL + "/v1/conversations/%s";
export const DELETE_SESSION_URL = API_BASE_URL + "/v1/conversations/%s";
export const GET_SESSIONS_URL =
  API_BASE_URL + "/v1/conversations?domain_id=%s&chat_mode=%s";
export const GET_SESSION_MESSAGES_URL =
  API_BASE_URL + "/v1/conversations/%s/messages";
export const GET_QUERY_INTENT_URL = API_BASE_URL + "/v1/query_intent";
export const GET_ENTITY_URL = API_BASE_URL + "/v1/fetch_entity";
export const GET_QUERY_TABULAR_DATA_URL =
  API_BASE_URL + "/v1/query_tabular_data";
export const GET_PROMPT_QUESTIONS = API_BASE_URL + "/v1/prompt_questions";
export const GET_PIPELINE_LOGS_URL = API_BASE_URL + "/v1/pipeline_logs/%s";
export const MESSAGE_FEEDBACK_URL = API_BASE_URL + "/v1/messages/%s/feedback";
export const DELETE_MESSAGE_FEEDBACK_URL =
  API_BASE_URL + "/v1/messages/%s/feedback";
export const FETCH_IMAGE_URL = API_BASE_URL + "/v1/fetch_image?image_url=%s";
export const BOOKMARK_UNMARK_MESSAGE_URL = API_BASE_URL + "/v1/messages/%s";

//Files
export const GET_FILES_URL = API_BASE_URL + "/v1/files";
export const UPLOAD_FILE_URL = API_BASE_URL + "/v1/files";
export const UPDATE_FILE_URL = API_BASE_URL + "/v1/files/%s";
export const DELETE_FILE_URL = API_BASE_URL + "/v1/files/%s";
export const GET_DOMAINS_URL = API_BASE_URL + "/v1/domains";

//transcription
export const TRANSCRIBE_AUDIO = API_BASE_URL + "/v1/transcribe";
