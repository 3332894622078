import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ChevronRightRounded } from "@mui/icons-material";
import { getAccountDetailsFromApi } from "../../core/repo/accountRepo";
import { useHistory } from "react-router-dom";
import { FILES_URL, Q_A_MODE_URL, SESSIONS_URL } from "../../utils/routes";
import { makeStyles } from "tss-react/mui";
import { usePostHog } from "posthog-js/react";
import { LOGIN_FAILURE, LOGIN_SUCCESS } from "../../utils/posthogEvents";
import Logo from "../../components/Logo";
import { useDispatch } from "react-redux";
import { getAllFiles } from "../../core/repo/fileRepo";

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100vh",
    height: "100svh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function Account() {
  const posthog = usePostHog();
  const { classes } = useStyles();
  const [key, setKey] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const getAccount = async () => {
    try {
      setError(false);
      setIsLoading(true);
      await dispatch(getAccountDetailsFromApi(key.toLowerCase()));
      posthog.capture(LOGIN_SUCCESS);
      // const files = await dispatch(getAllFiles());
      history.push({ pathname: Q_A_MODE_URL });
    } catch (ex) {
      console.log(ex);
      posthog.capture(LOGIN_FAILURE);
      setError(true);
    }

    setIsLoading(false);
  };

  return (
    <Box className={classes.container}>
      <Box
        p={{ md: 5, xs: 3 }}
        boxShadow={"0px 4px 8px 3px rgba(63, 81, 181, 0.12)"}
        borderRadius={6}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}>
          <Logo />
          <Box mb={1} />
          <Typography variant="h5">Welcome to Raven AI</Typography>
          <Box mb={3} />
          <Typography variant="subtitle2" textAlign={"center"}>
            Enter your account key
          </Typography>
        </Box>
        <Box mb={2} />
        <form>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}>
            <TextField
              label="Account key"
              sx={{ width: 300 }}
              InputProps={{ style: { borderRadius: 8 } }}
              value={key}
              onChange={(e) => {
                setKey(e.currentTarget.value);
              }}
            />
            <Box mb={2} />
            {!key || key.length === 0 ? (
              <Button
                fullWidth
                variant="contained"
                onClick={() => getAccount()}
                color="primary"
                type="submit"
                size="large"
                disabled={!key || key.length === 0}
                endIcon={<ChevronRightRounded />}>
                Get Started
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={isLoading}
                onClick={() => getAccount()}
                sx={{
                  background:
                    "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%) !important",
                }}>
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} size={26.5} />
                ) : (
                  "Get Started"
                )}
              </Button>
            )}
            {error && (
              <Typography variant="caption" color={"darkred"} sx={{ mt: 2 }}>
                Error
              </Typography>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Account;
