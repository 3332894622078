import React from "react";
import { Redirect } from "react-router-dom";
import { Q_A_MODE_URL, SESSIONS_URL } from "../utils/routes";
import { getUserDetails } from "../core/storage/localStorage";

function AuthGuard({ children }) {
  const user = getUserDetails();
  if (user) {
    return <Redirect to={Q_A_MODE_URL} />;
  }

  return children;
}

export default AuthGuard;
