import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SnippetFolderRounded, ChatRounded } from "@mui/icons-material";
import { NavLink as RouterLink } from "react-router-dom";
import { GradientIconLight } from "../../../components/GradientIcon";
import { useDispatch } from "react-redux";
import { getSessionMessagesSuccess } from "../../../core/events/sessionEvents";

const useStyles = makeStyles()((theme, _params, classes) => ({
  flexItem: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "clip",
  },
  menuItem: {
    padding: 8,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  collapsedMenuItem: {
    flexDirection: "column",
  },
  activeMenuItem: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightBg,
    [`& .${classes.label}`]: {
      fontWeight: 500,
      background: theme.palette.light,
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    [`& .${classes.icon}`]: {
      display: "none",
    },
    [`& .${classes.gradientIcon}`]: {
      display: "flex",
    },
  },
  label: {
    color: theme.palette.text.secondary,
  },
  icon: {
    display: "flex",
  },
  gradientIcon: {
    display: "none",
  },
}));

const tabs = [
  {
    value: "sessions",
    label: "Sessions",
    icon: ChatRounded,
  },
  {
    value: "files",
    label: "Files",
    icon: SnippetFolderRounded,
  },
];

function Menu({ isExpand, onClose }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const handleClick = () => {
    dispatch(getSessionMessagesSuccess([]));
    if (onClose) {
      onClose();
    }
  };

  return (
    <Box p={isExpand ? 2 : 1} className={classes.flexItem}>
      {tabs.map((tab) => {
        const { value, label, icon } = tab;
        const Icon = icon;
        const isActive =
          (pathname.startsWith("/sessions") && value === "sessions") ||
          (pathname.startsWith("/files") && value === "files");
        return (
          <Box
            key={value}
            component={RouterLink}
            exact
            to={`/${value}`}
            onClick={handleClick}
            className={`${classes.menuItem} ${isActive ? classes.activeMenuItem : null} ${
              !isExpand ? classes.collapsedMenuItem : null
            }`}
            sx={{
              "&:hover": {
                "& .MuiSvgIcon-root": {
                  color: theme.palette.primary.dark,
                },
                "& .MuiTypography-root": {
                  color: theme.palette.primary.dark,
                },
              },
            }}
          >
            <Box className={classes.gradientIcon}>
              <GradientIconLight Icon={icon} />
            </Box>
            <Box className={classes.icon}>
              <Icon sx={{ color: theme.palette.text.secondary }} />
            </Box>
            {isExpand ? (
              <Typography fontWeight={500} sx={{ marginLeft: 1 }} className={classes.label}>
                {label}
              </Typography>
            ) : (
              <Typography variant="caption2" className={classes.label}>
                {label}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default Menu;
