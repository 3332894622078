import React, { useRef, useState } from "react";
import {
  Box,
  Chip,
  Tooltip,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  formatDate,
  getColorShades,
  toTitleCase,
} from "../../../../../utils/utils";
import useIsOverflow from "../../../../../components/useIsOverflow";
import FileActionsMenu from "./FilesActionMenu";
import { DynamicIcon } from "../../../../../components/CustomIcons";
import { RotateLeftRounded } from "@mui/icons-material";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";

const useStyles = makeStyles()((theme) => ({
  gridViewFilesContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    rowGap: 16,
    columnGap: 20,
    [theme.breakpoints.down("sm")]: {
      rowGap: 12,
      columnGap: 16,
    },
  },
  gridViewFileItem: {
    width: 200,
    borderRadius: 16,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  disabledGridViewFileItem: {
    width: 200,
    borderRadius: 16,
    backgroundColor: theme.palette.background.light,
  },
}));

function GridView({
  files,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const { classes } = useStyles();
  return (
    <Box>
      <Box className={classes.gridViewFilesContainer}>
        {files.map((file) => {
          const { id } = file;
          return (
            <GridViewItem
              key={id}
              file={file}
              handleSelectFile={handleSelectFile}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
            />
          );
        })}
      </Box>
    </Box>
  );
}

const FILE_STATUSES = ["processed", "completed", "uploaded"];

export const notSupportedBrowserFiles = ["xls", "xlsx", "docx"];

function GridViewItem({
  file,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const { classes } = useStyles();
  const { name, file_type, file_url, created_at, status } = file;
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  //if file is completed or not
  let isFileCompleted = status === "completed";

  const handleOpenFileInNewTab = () => {
    if (notSupportedBrowserFiles.includes(file_type)) {
      window.open(file_url, "_blank", "noopener,noreferrer");
    } else {
      handleSelectFile(file, true);
    }
  };

  const handleClickFile = () => {
    if (file_type === "docx") {
      setIsConfirmationDialogOpen(true);
    } else {
      handleSelectFile(file, false);
    }
  };

  const handleClickYes = () => {
    handleSelectFile(file);
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      <Card
        elevation={0}
        className={
          isFileCompleted
            ? classes.gridViewFileItem
            : classes.disabledGridViewFileItem
        }>
        <CardActionArea
          sx={{
            height: "100%",
          }}
          disableRipple={true}
          disableTouchRipple={true}
          onClick={handleClickFile}>
          <CardContent sx={{ height: "100%" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"start"}
              mb={1.5}>
              {isFileCompleted ? (
                <img
                  src={getFileIconImage(file_type)}
                  alt="file_icon"
                  width={27}
                  height={30}
                />
              ) : (
                <Chip
                  label="Processing..."
                  size="small"
                  variant="filled"
                  sx={{
                    backgroundColor: "var(--orange-lighten-5, #FFF3E0);",
                    color: "var(--orange-darken-4, #E65100)",
                    "& .MuiChip-icon": {
                      color: "var(--orange-darken-4, #E65100)",
                    },
                  }}
                  icon={<RotateLeftRounded />}
                />
              )}
              <Box onClick={(e) => e.stopPropagation()}>
                <FileActionsMenu
                  file={file}
                  handleDeleteFile={handleDeleteFile}
                  handleUpdateFile={handleUpdateFile}
                  handleOpenFileInNewTab={handleOpenFileInNewTab}
                />
              </Box>
            </Box>
            <Tooltip title={name} arrow disableHoverListener={!isOverflow}>
              <Typography
                ref={ref}
                variant="subtitle2"
                fontWeight={400}
                sx={{
                  letterSpacing: "0.1px",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  height: "2.4em", // Assuming default line-height, adjust if needed
                  lineHeight: "1.2em", // Adjust based on your typography settings
                }}>
                {name}
              </Typography>
            </Tooltip>
            <Box mt={1}>
              <Typography variant="caption" color={"textSecondary"}>
                {formatDate(created_at)}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          title={"Download and open file?"}
          content={
            "This file cannot be opened in the browser. Do you want to download and open in your system?"
          }
          onNo={() => setIsConfirmationDialogOpen(false)}
          onYes={handleClickYes}
        />
      )}
    </>
  );
}

export function DomainChip({ domain, isFileCompleted = true }) {
  const { icon, color } = domain;
  const domainName = domain["domain"];
  const { lightest, main, dark } = getColorShades(color);
  return (
    <Chip
      size="small"
      label={toTitleCase(domainName)}
      icon={
        <DynamicIcon
          iconName={icon}
          sx={{ color: main, fontSize: 18, marginLeft: 0.5 }}
        />
      }
      sx={{
        color: dark,
        background: lightest,
        height: 24,
        "& .MuiChip-label": {
          fontSize: "0.75rem",
          padding: "0 8px",
        },
      }}
      disabled={!isFileCompleted}
    />
  );
}

export function getFileIconImage(fileType) {
  switch (fileType) {
    case "pdf": {
      return "/images/file_icons/pdf.svg";
    }
    case "docx": {
      return "/images/file_icons/word.svg";
    }
    case "txt": {
      return "/images/file_icons/txt.svg";
    }
    case "png":
    case "jpg":
    case "jpeg": {
      return "/images/file_icons/img.svg";
    }
    case "xlsx":
    case "csv":
    case "xls": {
      return "/images/file_icons/excel.svg";
    }
  }
}

export default GridView;
