import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  MenuItem,
  Typography,
  Tooltip,
  useTheme,
  IconButton,
  TextField,
  Button,
  Divider,
  Badge,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsOverflow from "../../../components/useIsOverflow";
import {
  SnippetFolder,
  SearchRounded,
  CloseRounded,
  CheckBoxRounded,
  CheckBoxOutlineBlankRounded,
  IndeterminateCheckBoxOutlined,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useSnackbar } from "notistack";
import { setSelectedFilesSuccess } from "../../../core/events/sessionEvents";
import { setSelectedFilesLocalStorage } from "../../../core/storage/localStorage";
import { getFileIconImage } from "../../Files/FilesView/Results/FilesList/GridView";
import { GradientIconAccent } from "../../../components/GradientIcon";

const useStyles = makeStyles()((theme, _params, classes) => ({
  container: {
    display: "flex",
    border: `1px solid #c4c4c4`,
    overflow: "hidden",
    borderRadius: 50,
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      [`& .${classes.text}`]: {
        width: 30,
        marginLeft: 8,
        opacity: 1,
      },
    },
  },
  active: {
    background: theme.palette.light,
    borderWidth: 0,
  },
  text: {
    width: 0,
    opacity: 0,
    marginLeft: 0,
    transition: "all 0.2s linear",
  },
  activeText: {
    marginLeft: 8,
  },
  paper: {
    width: 800,
  },
  list: {
    padding: 0,
  },
  resetBtn: {
    "&.Mui-disabled": {
      background: "none",
    },
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function FilesListDialogAnchor() {
  const { selectedFiles } = useSelector((state) => state.session);
  const { selectedDomain } = useSelector((state) => state.domain);
  const domainId = selectedDomain ? selectedDomain["id"] : null;
  const { completedFiles } = useSelector((state) => state.files);
  const domainFilteredFilesNonDataAnalysis = completedFiles.filter(
    (file) =>
      !["csv", "xlsx", "xls"].includes(file["file_type"]) &&
      (!domainId || domainId === file["domain_id"])
  );
  const selectedFilesInDomain = selectedFiles.filter(
    (file) => !domainId || domainId === file["domain_id"]
  );
  const isFilterApplied =
    selectedFilesInDomain.length > 0 &&
    selectedFilesInDomain.length != domainFilteredFilesNonDataAnalysis.length;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Ask within specific files">
        <IconButton onClick={handleOpen} edge="start">
          <Badge
            color="error"
            variant="dot"
            invisible={!isFilterApplied}
            overlap="circular">
            {isFilterApplied ? (
              <GradientIconAccent Icon={SnippetFolder} sx={{ fontSize: 24 }} />
            ) : (
              <SnippetFolder />
            )}
          </Badge>
        </IconButton>
      </Tooltip>
      {open && <FilesListDialog handleClose={handleClose} />}
    </>
  );
}

function FilesListDialog({ handleClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const theme = useTheme();
  const { files, completedFiles } = useSelector((state) => state.files);
  const { selectedFiles, selectedDomain } = useSelector(
    (state) => state.session
  );
  const domainId = selectedDomain ? selectedDomain["id"] : null;
  const selectedFilesInDomain = selectedFiles.filter(
    (file) => !domainId || domainId === file["domain_id"]
  );
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [query, setQuery] = useState("");

  const domainFilteredFiles = completedFiles.filter(
    (file) =>
      !["csv", "xlsx", "xls"].includes(file["file_type"]) &&
      (!domainId || domainId === file["domain_id"])
  );

  const [localSelectedFiles, setLocalSelectedFiles] = useState(
    selectedFilesInDomain.length > 0
      ? selectedFilesInDomain
      : domainFilteredFiles
  );

  // const isFilterApplied =
  //   localSelectedFiles.length > 0 &&
  //   localSelectedFiles.length != domainFilteredFiles.length;

  const isAllFilesSelected =
    localSelectedFiles.length === domainFilteredFiles.length;

  const isFilterChanged =
    JSON.stringify(localSelectedFiles.slice().sort()) !==
    JSON.stringify(selectedFilesInDomain.slice().sort());

  const isFilterRemoved = localSelectedFiles.length === 0;

  useEffect(() => {
    setFilteredFiles(domainFilteredFiles);
    setLocalSelectedFiles(
      selectedFilesInDomain.length > 0
        ? selectedFilesInDomain
        : domainFilteredFiles
    );
  }, [completedFiles, domainId]);

  function setSelectedFiles(files) {
    dispatch(setSelectedFilesSuccess(files));
    setSelectedFilesLocalStorage(files);
  }

  const handleQueryChange = (query) => {
    setQuery(query);
    let filteredFiles = [];
    if (!query) {
      filteredFiles = domainFilteredFiles;
    } else {
      query = query.toLowerCase();
      filteredFiles = domainFilteredFiles.filter((file) =>
        file["name"].toLowerCase().includes(query)
      );
    }
    setFilteredFiles(filteredFiles);
  };

  const handleResetFilters = () => {
    handleQueryChange("");
    setLocalSelectedFiles(
      selectedFilesInDomain.length > 0
        ? selectedFilesInDomain
        : domainFilteredFiles
    );
  };

  const handleApplyFilters = () => {
    if (localSelectedFiles.length === 0) {
      enqueueSnackbar("You must select at-least 1 file to proceed.", {
        variant: "warning",
      });
      return;
    }
    if (localSelectedFiles.length === files.length) {
      enqueueSnackbar("Selecting All files is the default setting.", {
        variant: "info",
      });
    }
    setSelectedFiles(localSelectedFiles);
    // const pattern = /^\/sessions\/[^\/]+(\/messages\/[^\/]+)?$/;
    // if (
    //   localSelectedFiles.length !== completedFiles.length &&
    //   pattern.test(location.pathname)
    // ) {
    //   const searchObj = {
    //     fileFilter: encodeURIComponent(
    //       JSON.stringify(localSelectedFiles.map((f) => f["id"]))
    //     ),
    //   };
    //   history.push({
    //     pathname: location.pathname,
    //     search: "?" + new URLSearchParams(searchObj).toString(),
    //     state: location.state,
    //   });
    // } else {
    //   history.push({
    //     pathname: location.pathname,
    //     state: location.state,
    //   });
    // }
    handleClose();
  };

  const handleClearFiles = () => {
    setLocalSelectedFiles([]);
  };

  const handleFileClick = (file) => {
    const { id } = file;
    const checked = localSelectedFiles.find((f) => f["id"] === id)
      ? false
      : true;
    if (checked) {
      setLocalSelectedFiles([...localSelectedFiles, file]);
    } else {
      const files = localSelectedFiles.filter((f) => f["id"] !== id);
      setLocalSelectedFiles(files);
    }
  };

  const domainName = selectedDomain ? selectedDomain["domain"] : "";

  return (
    <>
      <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ padding: 0 }}>
          <Box
            px={3}
            py={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Box>
              <Typography variant="h6">
                Ask within specific {domainName} files
              </Typography>
              <Box mt={1.5} display="flex" alignItems="center">
                <Typography variant="body2" mr={1} color={"textSecondary"}>
                  Showing Files:{" "}
                </Typography>
                {selectedDomain && (
                  <Chip
                    sx={{
                      fontWeight: 400,
                      color: theme.palette.text.secondary,
                    }}
                    size="small"
                    label={`Domain: ${domainName}`}
                    variant="outlined"
                    color="default"
                    outlineColor={theme.palette.text.secondary}
                  />
                )}
              </Box>
            </Box>
            <IconButton edge="end" onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <Box px={2} pb={1} pt={2}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search files"
              size={"small"}
              value={query}
              onChange={(e) => handleQueryChange(e.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchRounded
                    sx={{ mr: 1, color: theme.palette.action.active }}
                  />
                ),
                style: { borderRadius: 8 },
              }}
            />
          </Box>
          {
            <Box
              px={2}
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1}
              my={0.5}
              maxHeight={116}
              overflow={"scroll"}>
              <Box display={"flex"} alignItems={"center"}>
                {isAllFilesSelected ? (
                  <IconButton onClick={handleClearFiles}>
                    <CheckBoxRounded />
                  </IconButton>
                ) : localSelectedFiles.length === 0 ? (
                  <IconButton
                    onClick={() => setLocalSelectedFiles(domainFilteredFiles)}>
                    <CheckBoxOutlineBlankRounded
                      sx={{ color: theme.palette.text.primary }}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleClearFiles}>
                    <IndeterminateCheckBoxOutlined
                      sx={{ color: theme.palette.text.primary }}
                    />
                  </IconButton>
                )}
                {/* {localSelectedFiles.length < filteredFiles.length ? (
                  <IconButton
                    onClick={() => setLocalSelectedFiles(domainFilteredFiles)}>
                    <CheckBoxOutlineBlank />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleClearFiles}>
                    <IndeterminateCheckBox
                      sx={{ color: theme.palette.text.primary }}
                    />
                  </IconButton>
                )} */}
              </Box>
              {!isFilterRemoved && (
                <Typography variant="caption" color={"textSecondary"}>
                  AI will answer within:
                </Typography>
              )}
              {isAllFilesSelected ? (
                <Chip
                  sx={{ fontWeight: 400 }}
                  size="small"
                  key={"All Files"}
                  label={"All Files"}
                  onDelete={handleClearFiles}
                />
              ) : (
                localSelectedFiles.map((file) => (
                  <Chip
                    sx={{ fontWeight: 400 }}
                    size="small"
                    key={file["id"]}
                    label={file["name"]}
                    onDelete={() => handleFileClick(file)}
                  />
                ))
              )}
              {!isFilterRemoved && !isAllFilesSelected && (
                <Button
                  variant="text"
                  sx={{ borderRadius: 16 }}
                  onClick={handleClearFiles}
                  size="small">
                  <span className={classes.gradientText}>Clear all</span>
                </Button>
              )}
            </Box>
          }
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ paddingX: 0, my: 2 }}>
          {filteredFiles.map((file, index) => {
            const { id } = file;
            return (
              <File
                key={id}
                index={index}
                file={file}
                localSelectedFiles={localSelectedFiles}
                handleFileClick={handleFileClick}
              />
            );
          })}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            className={classes.resetBtn}
            variant="text"
            onClick={handleResetFilters}
            disabled={!isFilterChanged}>
            <span className={isFilterChanged ? classes.gradientText : null}>
              Reset
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
            disabled={!isFilterChanged || isFilterRemoved}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function File({ index, file, localSelectedFiles, handleFileClick }) {
  const { id, name, file_type } = file;
  const theme = useTheme();
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const isFileSelcted = localSelectedFiles.find((f) => f["id"] === id)
    ? true
    : false;

  return (
    <Tooltip title={name} arrow disableHoverListener={!isOverflow}>
      <MenuItem
        onClick={() => handleFileClick(file)}
        sx={{
          maxWidth: "100%",
          background: index % 2 === 0 ? "#F5F5F5" : "white",
          paddingX: 2,
        }}>
        {isFileSelcted ? (
          <IconButton>
            <CheckBoxRounded sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        ) : (
          <IconButton>
            <CheckBoxOutlineBlankRounded />
          </IconButton>
        )}
        <Box mr={1.5} />
        <img
          src={getFileIconImage(file_type)}
          alt="file_icon"
          width={16}
          height={16}
        />
        {/* <PDFIcon /> */}
        <Box mr={1} />
        <Typography ref={ref} noWrap>
          {name}
        </Typography>
      </MenuItem>
    </Tooltip>
  );
}

export default FilesListDialogAnchor;
