import { getAccountIdFromLocal } from "../repo/accountRepo";
import { getAccountDetails, getUserDetails } from "../storage/localStorage";
import axios from "./axios";
import {
  CHAT_METADATA_URL,
  CHAT_URL,
  GET_ENTITY_URL,
  GET_QUERY_INTENT_URL,
  GET_QUERY_TABULAR_DATA_URL,
} from "./url";

class ChatService {
  chatWithAI = async (
    query,
    conversationId,
    questionId,
    threadId,
    fileIds,
    concise,
    model,
    domainId
  ) => {
    try {
      const { id, session_jwt } = getUserDetails();
      const { id: account_id } = getAccountDetails();
      const response = await fetch(CHAT_URL, {
        method: "POST",
        headers: {
          "x-session-jwt": session_jwt || "",
          "x-user-id": id,
          "x-account-id": account_id,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          conversation_id: conversationId,
          question_id: questionId,
          thread_id: threadId,
          file_ids: fileIds,
          concise: concise,
          debug: true,
          domain_id: domainId,
          use_claude:
            process.env.REACT_APP_ENV === "prod"
              ? false
              : model === "claude-3.5-sonnet",
        }),
      });
      return response.body;
    } catch (error) {
      console.error("Stream fetching failed:", error);
    }
  };

  getChatMetaData = (questionId) =>
    new Promise((resolve, reject) => {
      axios
        .post(CHAT_METADATA_URL, {
          question_id: questionId,
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getQueryIntent = (
    query,
    conversationId,
    questionId,
    threadId,
    fileIds,
    concise,
    model,
    domainId
  ) =>
    new Promise((resolve, reject) => {
      axios
        .post(GET_QUERY_INTENT_URL, {
          query,
          conversation_id: conversationId,
          question_id: questionId,
          thread_id: threadId,
          file_ids: fileIds,
          concise: concise,
          debug: true,
          domain_id: domainId,
          use_claude:
            process.env.REACT_APP_ENV === "prod"
              ? false
              : model === "claude-3.5-sonnet",
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getEntity = (
    queryIntent,
    query,
    conversationId,
    questionId,
    threadId,
    fileIds,
    concise,
    model,
    domainId
  ) =>
    new Promise((resolve, reject) => {
      axios
        .post(GET_ENTITY_URL, {
          query_intent: queryIntent,
          query,
          conversation_id: conversationId,
          question_id: questionId,
          thread_id: threadId,
          file_ids: fileIds,
          concise: concise,
          debug: true,
          domain_id: domainId,
          use_claude:
            process.env.REACT_APP_ENV === "prod"
              ? false
              : model === "claude-3.5-sonnet",
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getQueryTabularData = (
    queryIntent,
    query,
    conversationId,
    questionId,
    threadId,
    fileIds,
    concise,
    model,
    domainId
  ) =>
    new Promise((resolve, reject) => {
      axios
        .post(GET_QUERY_TABULAR_DATA_URL, {
          query_intent: queryIntent,
          query,
          conversation_id: conversationId,
          question_id: questionId,
          thread_id: threadId,
          file_ids: fileIds,
          concise: concise,
          debug: true,
          domain_id: domainId,
          use_claude:
            process.env.REACT_APP_ENV === "prod"
              ? false
              : model === "claude-3.5-sonnet",
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const chatService = new ChatService();

export default chatService;
