import React, { useState } from "react";
import {
  Menu,
  Box,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Tooltip,
} from "@mui/material";
import { DynamicIcon } from "../../../components/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDomainSuccess } from "../../../core/events/domainEvents";
import { ExpandMoreRounded } from "@mui/icons-material";
import {
  getSessionMessagesSuccess,
  getSessionsSuccess,
} from "../../../core/events/sessionEvents";

function SelectDomain({ expanded }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { domains, selectedDomain } = useSelector((state) => state.domain);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDomain = (domain) => {
    setSelectedDomain(domain);
    handleClose();
  };

  function setSelectedDomain(domain) {
    dispatch(setSelectedDomainSuccess(domain));
    dispatch(getSessionsSuccess([]));
    dispatch(getSessionMessagesSuccess([]));
  }

  return (
    selectedDomain && (
      <Box px={1.5} mb={2}>
        {!expanded ? (
          <Tooltip title="Select Domain" arrow>
            <Box
              sx={{
                cursor: "pointer",
                "&:hover": {
                  background: theme.palette.action.hover,
                  borderRadius: 1,
                },
              }}
              onClick={handleClick}>
              <Box
                width={32}
                borderRadius={1.5}
                bgcolor={"#E8EAF6"}
                display={"flex"}
                p={0.5}
                margin={"0 auto"}>
                <DynamicIcon iconName={selectedDomain["icon"]} />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <Typography fontSize={12} noWrap>
                  {selectedDomain["domain"]}
                </Typography>
                <ExpandMoreRounded sx={{ fontSize: 18 }} />
              </Box>
            </Box>
          </Tooltip>
        ) : (
          <>
            <Box mb={0.5} pl={1}>
              <Typography variant="caption" color={"textSecondary"}>
                Domain
              </Typography>
            </Box>
            <Box
              p={1}
              borderRadius={25}
              sx={{
                display: "flex",
                alignItems: "center",
                background: "#E8EAF6",
                cursor: "pointer",
                transition: "all 0.5s linear",
                "&:hover": {
                  opacity: 0.7,
                },
              }}
              onClick={handleClick}>
              <DynamicIcon
                iconName={selectedDomain["icon"]}
                sx={{ color: theme.palette.text.primary }}
              />
              <Box mr={1} />
              <Typography fontSize={13} width={"100%"}>
                {selectedDomain["domain"]}
              </Typography>
              <Box mr={1} />
              <ExpandMoreRounded sx={{ color: theme.palette.text.primary }} />
            </Box>
          </>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: { sx: { borderRadius: 2, minWidth: 100, mt: 1 } },
          }}>
          {domains.map((d) => {
            const { id, domain, icon } = d;
            return (
              <MenuItem
                key={id}
                onClick={() => handleSelectDomain(d)}
                selected={selectedDomain["id"] === id}>
                <ListItemIcon>
                  <DynamicIcon iconName={icon} />
                </ListItemIcon>
                <ListItemText>{domain}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
        {/* <Chip
        size="medium"
        sx={{ background: "#E8EAF6", width: "100%" }}
        label={selectedDomain["domain"]}
        icon={
          <DynamicIcon
            iconName={selectedDomain["icon"]}
            sx={{ color: theme.palette.text.primary }}
          />
        }
        deleteIcon={
          <ExpandMoreRounded
            sx={{ color: `${theme.palette.text.primary} !important` }}
          />
        }
        onDelete={() => {}}
      /> */}
        {/* <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Domain</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={selectedDomain ? selectedDomain["id"] : ""}
          label="Select Domain"
          onChange={handleDomainChange}
          renderValue={(value) => {
            const domain = domains.find((d) => d["id"] === value);
            return (
              <Box display={"flex"} alignItems={"center"}>
                <DynamicIcon
                  iconName={domain["icon"]}
                  sx={{ color: theme.palette.text.secondary }}
                />
                <Box mr={1} />
                <Typography color="textSecondary">
                  {domain["domain"]}
                </Typography>
              </Box>
            );
          }}
          fullWidth
          sx={{ background: "white", borderRadius: 2 }}>
          {domains.map((d) => {
            const { id, domain, icon } = d;
            return (
              <MenuItem value={id} key={id}>
                <ListItemIcon>
                  <DynamicIcon iconName={icon} />
                </ListItemIcon>
                <ListItemText>{domain}</ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl> */}
      </Box>
    )
  );
}

export default SelectDomain;
