import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";

function TextViewer({ file }) {
  const { file_url } = file;
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    // Fetch the TXT file content
    const fetchFileContent = async () => {
      try {
        const response = await fetch(file_url);
        const text = await response.text();
        setFileContent(text);
      } catch (error) {
        console.error("Error fetching the TXT file:", error);
      }
    };

    fetchFileContent();
  }, [file_url]);
  return (
    <Box maxWidth={900} margin={"0 auto"} bgcolor={"white"} p={1}>
      <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
        {fileContent}
      </pre>
    </Box>
  );
}

export default TextViewer;
