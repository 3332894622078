import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { deleteFile, updateFile } from "../../core/repo/fileRepo";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import ViewFile from "./ViewFile";
import Page from "../../components/Page";
import { makeStyles } from "tss-react/mui";
import { usePostHog } from "posthog-js/react";
import {
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  EDIT_FILE_FAILURE,
  EDIT_FILE_SUCCESS,
  FILE_CLICK,
  GO_BACK_TO_FILES,
} from "../../utils/posthogEvents";
import { FILES_URL } from "../../utils/routes";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import FilesView from "./FilesView";
import { notSupportedBrowserFiles } from "./FilesView/Results/FilesList/GridView";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
}));

function Files() {
  const posthog = usePostHog();
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { files, loading, isFetched } = useSelector((state) => state.files);

  const [file, setFile] = useState(null);
  const fileId = new URLSearchParams(location.search).get("fileId");

  useEffect(() => {
    if (files.length > 0) fetchFileFromUrl();
  }, [files, fileId]);

  function getFileById(id) {
    return files.find((file) => file["id"] === id);
  }

  function fetchFileFromUrl() {
    const file = getFileById(fileId);
    if (file) {
      setFile(file);
    } else {
      setFile(null);
      history.push({
        pathname: FILES_URL,
      });
    }
  }

  const handleDeleteFile = async (fileId) => {
    try {
      await dispatch(deleteFile(fileId));
      setFile(null);
      enqueueSnackbar("File has been deleted", {
        variant: "success",
      });
      history.push({
        pathname: FILES_URL,
      });
      posthog.capture(DELETE_FILE_SUCCESS);
    } catch (e) {
      posthog.capture(DELETE_FILE_FAILURE);
      console.log(e);
    }
  };

  const handleUpdateFile = async (file) => {
    try {
      await dispatch(updateFile(file));
      enqueueSnackbar("File name has been updated", {
        variant: "success",
      });
      posthog.capture(EDIT_FILE_SUCCESS);
    } catch (e) {
      console.log(e);
      posthog.capture(EDIT_FILE_FAILURE);
    }
  };

  const handleSelectFile = (file, isNewTab = false) => {
    const { id, file_type, file_url } = file;
    if (file_type === "docx") {
      window.open(file_url, "_blank", "noopener,noreferrer");
    } else {
      setFile(file);
      posthog.capture(FILE_CLICK);
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("fileId", id);
      if (isNewTab) {
        window.open(url.toString(), "_blank");
      } else {
        history.push({
          pathname: FILES_URL,
          search: url.search,
        });
      }
    }
  };

  const handleGoBack = () => {
    setFile(null);
    posthog.capture(GO_BACK_TO_FILES);
    history.push({
      pathname: FILES_URL,
    });
  };

  return (
    <Page className={classes.root} title={"Files"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        {fileId && file ? (
          <ViewFile
            file={file}
            handleGoBack={handleGoBack}
            handleDeleteFile={handleDeleteFile}
            handleUpdateFile={handleUpdateFile}
          />
        ) : (
          <FilesView
            files={files}
            isFetched={isFetched}
            handleSelectFile={handleSelectFile}
            handleDeleteFile={handleDeleteFile}
            handleUpdateFile={handleUpdateFile}
          />
        )}
        {loading && <Loader />}
      </Box>
    </Page>
  );
}

export default Files;
