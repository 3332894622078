import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormHelperText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";
import { EditRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../core/repo/accountRepo";
import { useSnackbar } from "notistack";
import { usePostHog } from "posthog-js/react";
import {
  UPDATE_USERNAME_FAILURE,
  UPDATE_USERNAME_SUCCESS,
} from "../../../../utils/posthogEvents";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

const validationSchema = yup.object({
  name: yup.string().trim().required("Username is required."),
});

function EditUsername({ user, isCenter = false, children }) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={isCenter ? "center" : "space-between"}>
        {children}
        <Tooltip
          title={"Edit user name"}
          PopperProps={{
            sx: { zIndex: 1701 },
          }}>
          <IconButton size="small" sx={{ ml: 1 }} onClick={handleOpen}>
            <EditRounded sx={{ fontSize: 16 }} />
          </IconButton>
        </Tooltip>
      </Box>
      {open && <EditUsernameDialog user={user} onClose={handleClose} />}
    </>
  );
}

function EditUsernameDialog({ user, onClose }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const posthog = usePostHog();
  const initialValues = {
    name: user["name"],
  };

  async function onSubmit(values, { resetForm }) {
    try {
      await dispatch(updateUser({ ...user, name: values["name"] }));
      window.dispatchEvent(new Event("storage"));
      onClose();
      enqueueSnackbar("Usename has been updated.", { variant: "success" });
      posthog.capture(UPDATE_USERNAME_SUCCESS);
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to update username.", { variant: "error" });
      posthog.capture(UPDATE_USERNAME_FAILURE);
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "sm",
        },
      }}
      sx={{ zIndex: 1800 }}>
      <DialogTitle variant="h6" sx={{ display: "flex", alignItems: "center" }}>
        Rename Username
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            sx={{ marginTop: 1 }}
            variant="outlined"
            label="Username"
            name="name"
            error={Boolean(formik.errors.name)}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.errors.title && (
            <FormHelperText error sx={{ ml: 1 }}>
              {formik.errors.name}
            </FormHelperText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          <span className={classes.gradientText}>Cancel</span>
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditUsername;
