import React, { useRef, useState, useEffect } from "react";
import { Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import Message from "./Message";
import moment from "moment";
import { makeStyles } from "tss-react/mui";
import { debounce } from "lodash";

const useStyles = makeStyles()((theme) => ({
  dividerWrapper: {
    padding: 0,
  },
  sticky: {
    position: "absolute",
    top: 4,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
  },
}));

function Results({
  source = null,
  messages,
  handleSourceChange,
  handleEntityChange,
  messagesRef,
  messageRefs,
  isStreaming,
  queryContainerHeight,
}) {
  const messagesGroup = groupMessagesByDate(messages);
  return (
    <Box>
      {messagesGroup.map((message, i) => {
        return (
          <MessagesGroup
            key={i}
            source={source}
            messageGroup={message}
            messagesRef={messagesRef}
            messageRefs={messageRefs}
            queryContainerHeight={queryContainerHeight}
            handleSourceChange={handleSourceChange}
            handleEntityChange={handleEntityChange}
            isStreaming={isStreaming}
            isLastGroup={i === messagesGroup.length - 1}
          />
        );
      })}
    </Box>
  );
}

function MessagesGroup({
  source,
  messageGroup,
  handleSourceChange,
  handleEntityChange,
  messagesRef,
  messageRefs,
  queryContainerHeight,
  isLastGroup,
  isStreaming,
}) {
  const { date, items: messages } = messageGroup;
  return (
    <Box>
      <StickyDate date={date} messagesRef={messagesRef} />
      {messages.map((message, i) => {
        return (
          <Message
            key={i}
            source={source}
            message={message}
            messagesRef={messagesRef}
            messageRefs={messageRefs}
            handleSourceChange={handleSourceChange}
            handleEntityChange={handleEntityChange}
            queryContainerHeight={queryContainerHeight}
            isStreaming={isStreaming}
            isLastMessage={i === messages.length - 1}
            isLastGroup={isLastGroup}
          />
        );
      })}
    </Box>
  );
}

function StickyDate({ date, messagesRef }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);

  const handleScroll = () => {
    if (stickyRef.current) {
      setIsSticky(messagesRef.current.scrollTop > stickyRef.current.offsetTop);
    }
  };

  const debouncedScroll = useRef(debounce(handleScroll, 5));

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.addEventListener("scroll", debouncedScroll.current);
    }
    return () => {
      if (messagesRef.current)
        messagesRef.current.removeEventListener("scroll", debouncedScroll.current);
    };
  }, []);

  return (
    <div ref={stickyRef}>
      <Divider
        classes={{ wrapper: classes.dividerWrapper }}
        sx={{
          visibility: isSticky ? "hidden" : "visible",
        }}
      >
        <Typography
          variant="caption"
          component="span"
          sx={{
            display: "inline-block",
            width: 120,
            padding: "5px 10px",
            background: "white",
            border: `1px solid ${theme.palette.divider}`,
            color: theme.palette.text.secondary,
            borderRadius: "16px",
            textAlign: "center",
          }}
        >
          {date}
        </Typography>
      </Divider>
      {isSticky && (
        <Typography
          variant="caption"
          component="span"
          className={classes.sticky}
          sx={{
            display: "inline-block",
            width: 120,
            padding: "5px 10px",
            background: "white",
            border: `1px solid ${theme.palette.divider}`,
            color: theme.palette.text.secondary,
            borderRadius: "16px",
            textAlign: "center",
          }}
        >
          {date}
        </Typography>
      )}
    </div>
  );
}

export function groupMessagesByDate(items) {
  const today = moment();
  const yesterday = moment().subtract(1, "day");
  const groups = {};
  items.forEach((item) => {
    const date = moment(item["query"]["created_at"]);
    const dayFormat = date.format("ddd, DD MMM");
    if (date.isSame(today, "day")) {
      if (groups["Today"]) {
        groups["Today"].push(item);
      } else {
        groups["Today"] = [item];
      }
    } else if (date.isSame(yesterday, "day")) {
      if (groups["Yesterday"]) {
        groups["Yesterday"].push(item);
      } else {
        groups["Yesterday"] = [item];
      }
    } else {
      if (groups[dayFormat]) {
        groups[dayFormat].push(item);
      } else {
        groups[dayFormat] = [item];
      }
    }
  });

  const groupsArray = Object.keys(groups).map((date) => {
    return {
      date,
      items: groups[date],
    };
  });
  return groupsArray;
}

export default Results;
