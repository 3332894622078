import { Check, Close, KeyboardVoice, Stop } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useAudioRecorder } from "react-audio-voice-recorder";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Lottie from "react-lottie";
import { LiveAudioVisualizer } from "react-audio-visualize";
import animationData from "../../../lotties/voice-recording.json";
import { AudioWaveform } from "../../../components/AudioWaveform";
import { transcribeAudio } from "../../../core/repo/fileRepo";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
  },
  root: {
    margin: 0,
    backgroundColor: "#333",
    borderRadius: 16,
    padding: "16px 12px",
  },
  fullwidth: {
    width: "100%", // Ensures the container is full width
  },
  crossBtn: {
    borderRadius: "50%",
    // background: theme.palette.,
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    // },
  },
  checkBtn: {
    borderRadius: "50%",
    background: theme.palette.light,
    marginLeft: 8,
  },
  checkBDisabledtn: {
    borderRadius: "50%",
    color: "gray",
  },
}));

export function Audio({ recordingError, recordingCancelled, recordingText }) {
  const theme = useTheme();
  const { classes } = useStyles();

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    mediaRecorder,
    recordingTime,
  } = useAudioRecorder(
    { echoCancellation: true, noiseSuppression: true },
    recordingError,
    {}
  );

  const [processingAudio, setProcessingAudio] = useState(false);

  useEffect(() => {
    if (recordingBlob) {
      transcribe();
    }
  }, [recordingBlob]);

  async function transcribe() {
    try {
      const audiofile = new File([recordingBlob], "audio.mp3", {
        type: "audio/mpeg",
      });
      // downloadAudioFile(audiofile);
      const response = await transcribeAudio(audiofile);
      recordingText(response);
    } catch (ex) {
      recordingText(null);
    }
  }

  function downloadAudioFile(audioFile) {
    // Create a URL from the file
    const url = URL.createObjectURL(audioFile);

    // Create an anchor element and set the URL as the href
    const a = document.createElement("a");
    a.href = url;
    a.download = audioFile.name || "download.mp3"; // Set the default file name for the download

    // Append the anchor to the body, trigger click, and then remove it
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the URL after download
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    startRecording();
    // Optionally, return a function to stop recording when the component unmounts
    return () => stopRecording();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const { key } = e;
      if (key === "Enter" && isRecording) {
        e.preventDefault();
        doneRecording();
      }
      if (key === "Escape" && isRecording) {
        e.preventDefault();
        cancelRecording();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isRecording]);

  function doneRecording() {
    stopRecording();
    setProcessingAudio(true);
  }

  function cancelRecording() {
    stopRecording();
    recordingCancelled();
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      width={"100%"}
      borderRadius={16}
      className={classes.fullwidth}>
      {(processingAudio || mediaRecorder) && (
        <IconButton
          className={classes.crossBtn}
          size="small"
          onClick={() => {
            cancelRecording();
          }}>
          <Tooltip title="Cancel">
            <Close
              sx={{ color: theme.palette.secondary.dark }}
              fontSize="small"
            />
          </Tooltip>
        </IconButton>
      )}
      <Box
        ml={2}
        width={"100%"}
        // minHeight={57}
        // marginTop={0.5}
        display={"flex"}
        alignItems={"center"}>
        {processingAudio ? (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <CircularProgress size={16} color="primary" />
            <Typography variant="body2" textAlign={"center"} marginLeft={1}>
              Processing...
            </Typography>
          </Box>
        ) : mediaRecorder ? (
          <Box display={"flex"} alignItems={"center"} width={"100%"}>
            <AudioWaveform
              mediaRecorder={mediaRecorder}
              barColor={theme.palette.primary.main}
              gap={2}
            />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              {Math.floor(recordingTime / 60)
                .toString()
                .padStart(2, "0")}
              :{(recordingTime % 60).toString().padStart(2, "0")}
            </Typography>
          </Box>
        ) : (
          <Box display={"flex"} justifyContent={"center"} width={"100%"}>
            <CircularProgress size={16} color="primary" />
          </Box>
        )}
        {mediaRecorder && (
          <IconButton
            className={classes.checkBtn}
            size="small"
            edge="end"
            disabled={processingAudio}
            onClick={() => {
              doneRecording();
            }}>
            <Tooltip title="Done Speaking">
              <Check sx={{ color: "white" }} fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
