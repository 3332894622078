import React, { useState, useCallback } from "react";
import { uploadFile } from "../core/repo/fileRepo";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
  useTheme,
  DialogActions,
} from "@mui/material";
import { useSnackbar, closeSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
  Delete,
  UpdateRounded,
  UploadFile,
  UploadFileRounded,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { GradientIconAccent } from "./GradientIcon";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { getFileIconImage } from "../views/Files/FilesView/Results/FilesList/GridView";
import { fileTypeObj } from "../utils/utils";

const useStyles = makeStyles()((theme) => ({
  notificationContainer: {
    background: "#323232",
    zIndex: 1599,
    borderRadius: 12,
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  underline: {
    textDecoration: "underline",
    textDecorationColor: theme.palette.accent,
    textUnderlineOffset: 4,
  },
}));

function FileUpload({ children, ...rest }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <div
        {...rest}
        onClick={() => {
          setIsDialogOpen(true);
        }}>
        {children}
      </div>
      {isDialogOpen && (
        <FileUploadDialog onClose={() => setIsDialogOpen(false)} />
      )}
    </>
  );
}

function FileUploadDialog({ onClose }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { selectedDomain } = useSelector((state) => state.domain);
  const [files, setFiles] = useState([]);
  const [showProgress, setShowProgress] = useState(true);
  const [progressInfo, setProgressInfo] = useState([]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const notDuplicateFiles = acceptedFiles.filter((file) => {
        return files.find((f) => f["name"] === file["name"]) ? false : true;
      });
      setFiles((prevFiles) => [...prevFiles].concat(notDuplicateFiles));
    },
    [files]
  );

  const handleDeleteFile = (file) => {
    const filteredFiles = files.filter((f) => f["name"] !== file["name"]);
    setFiles(filteredFiles);
  };

  const handleUploadFiles = async () => {
    try {
      let progressInfos = files.map((file) => ({
        progress: 0,
        loaded: 0,
        total: 0,
        fileName: file.name,
      }));

      const uploadPromises = files.map((file, i) =>
        dispatch(
          uploadFile(file, selectedDomain["id"], (event) => {
            const { loaded, total, progress } = event;
            let temp = { fileName: file["name"], loaded, total, progress };
            let copy = [...progressInfos];
            copy[i] = temp;
            progressInfos = [...copy];
            setProgressInfo([...copy]);
          })
        )
      );
      await Promise.all(uploadPromises);
      enqueueSnackbar("", {
        content: (key) => (
          <Box
            px={2}
            py={1}
            display={"flex"}
            alignItems={"flex-start"}
            className={classes.notificationContainer}>
            <UpdateRounded sx={{ color: "#ffffff" }} />
            <Box ml={1.5} mr={2}>
              <Typography fontWeight={500} sx={{ color: "#ffffff", mb: 0.5 }}>
                {files.length} Files Processing
              </Typography>
              <Typography variant="body2" sx={{ color: "#ffffff" }}>
                We will notify you when it’s done
              </Typography>
            </Box>
            <Button
              size="small"
              variant="text"
              sx={{ color: "#82B1FF" }}
              onClick={() => closeSnackbar(key)}>
              Dismiss
            </Button>
          </Box>
        ),
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    } catch (err) {
      if (err["response"]) {
        const { status } = err["response"];
        if (status === 400) {
          enqueueSnackbar("Invalid File", { variant: "error" });
        }
      }
      console.log(err);
    }
    setProgressInfo([]);
    setFiles([]);
    onClose();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [], // Accepts .png files
      "image/jpeg": [], // Accepts .jpeg files
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [], // Accepts .docx files
      "text/plain": [], // Accepts .txt files
      "application/vnd.ms-excel": [], // Accepts .xls files
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // Accepts .xlsx files
      "text/csv": [], // Accepts .csv files
      "application/pdf": [], // Accepts .pdf files
    },
    onDrop: handleDrop,
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle variant="h6">
        Upload files to {selectedDomain["domain"]} domain
      </DialogTitle>
      <DialogContent>
        <div
          className={clsx({
            [classes.dropZone]: true,
            [classes.dragActive]: isDragActive,
          })}
          {...getRootProps()}>
          <input
            accept=".png,.jpeg,.docx,.txt,.xls,.xlsx,.csv,.pdf"
            {...getInputProps()}
          />
          <Box
            px={2}
            py={3}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            borderRadius={1}
            sx={{
              border: `1px dashed ${theme.palette.divider}`,
              cursor: "pointer",
            }}>
            <GradientIconAccent Icon={UploadFile} sx={{ fontSize: 48 }} />
            <Box mb={2} />
            <Typography>
              <span className={`${classes.gradientText} ${classes.underline}`}>
                Click to upload
              </span>{" "}
              or drag and drop
            </Typography>
            <Box mb={1} />
            <Typography color={"textSecondary"} variant="body2" mb={1} mt={2}>
              Documents (PDF or Doc), Images, Excel Sheets
            </Typography>
            <Typography color={"textSecondary"} variant="caption">
              Supported formats: pdf, txt, docx, png, jpg, csv, xls or xlsx
            </Typography>
          </Box>
        </div>
        <Box my={3}>
          {files && files.length > 0 && (
            <Typography variant="body2" color={"textSecondary"} mb={1}>
              {files.length} {files.length > 1 ? "files" : "file"}{" "}
              {" to be uploaded"}
            </Typography>
          )}
          {files.map((file, i) => {
            const { name, type } = file;
            return (
              <>
                <Box
                  key={i}
                  py={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} mr={2}>
                    <img
                      src={getFileIconImage(fileTypeObj[type])}
                      width={24}
                      height={24}
                      alt={type}
                    />
                    <Box mr={2} />
                    <Typography variant="body1">{name}</Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteFile(file)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
                <Divider ml={2} sx={{ height: "5px" }} />
              </>
            );
          })}
        </Box>
        {progressInfo.length > 0 && showProgress && (
          <FileUploadStatus
            progressInfo={progressInfo}
            onClose={() => setShowProgress(false)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button>
          <span className={classes.gradientText} onClick={onClose}>
            Cancel
          </span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={files.length === 0}
          onClick={handleUploadFiles}>
          Confirm Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FileUploadStatus({ progressInfo, onClose }) {
  const { classes } = useStyles();
  const [isExpand, setIsExpand] = useState(false);
  const total = getSum("total");
  const loaded = getSum("loaded");
  const overallProgress = Math.floor((loaded * 100) / total);
  const numProcessing = progressInfo.filter((p) => p["progress"] !== 1).length;

  function getSum(field) {
    let res = 0;
    progressInfo.forEach((p) => {
      res += p[field];
    });
    return res;
  }

  const handleExpandChange = () => {
    setIsExpand(!isExpand);
  };

  return (
    <Box
      className={classes.notificationContainer}
      position={"fixed"}
      bottom={24}
      right={24}>
      <Box py={1} px={1.5}>
        <Box display={"flex"} alignItems={"center"}>
          <Avatar sx={{ backgroundColor: "#424242" }}>
            <UploadFileRounded />
          </Avatar>
          <Box mr={1} />
          <Typography sx={{ color: "#ffffff" }}>
            {numProcessing === 0
              ? `${progressInfo.length} Files Uploaded`
              : `Uploading ${numProcessing} ${
                  numProcessing > 1 ? "items" : "item"
                }`}
          </Typography>
          <Box mr={1} />
          {isExpand ? (
            <IconButton onClick={handleExpandChange}>
              <ChevronLeftRounded
                sx={{ transform: "rotate(90deg)", color: "#ffffff" }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={handleExpandChange}>
              <ChevronRightRounded
                sx={{ transform: "rotate(90deg)", color: "#ffffff" }}
              />
            </IconButton>
          )}
          <Box mr={1} />
          <IconButton onClick={onClose}>
            <CloseRounded sx={{ color: "#ffffff" }} />
          </IconButton>
        </Box>
        {!isExpand && (
          <LinearProgress
            variant="determinate"
            color="success"
            value={overallProgress}
            sx={{ borderRadius: 2, mt: 1 }}
          />
        )}
      </Box>
      {isExpand && (
        <>
          <Divider sx={{ borderColor: "#424242" }} />
          <Box py={1} px={2} maxHeight={180} overflow={"scroll"}>
            {progressInfo.map((p, i) => {
              const { fileName, progress } = p;
              const isCompleted = progress === 1;
              return (
                <Box
                  key={i}
                  p={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} mr={1}>
                    <Box
                      display={"flex"}
                      mr={1}
                      borderRadius={0.5}
                      bgcolor={"#EB4435"}
                      px={0.25}
                      py={0.5}>
                      <Typography
                        fontSize={7}
                        fontWeight={700}
                        fontFamily={"Roboto Condensed"}
                        sx={{ color: "#ffffff", lineHeight: "normal" }}>
                        PDF
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        color: isCompleted
                          ? "#ffffff"
                          : "rgba(255, 255, 255, 0.54)",
                      }}>
                      {fileName}
                    </Typography>
                  </Box>
                  {isCompleted ? (
                    <CheckCircleRounded color="success" />
                  ) : (
                    <CircularProgress
                      size={24}
                      variant="determinate"
                      color="info"
                      value={Math.round(progress * 100)}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
}

export default FileUpload;
