import axios from "./axios";
import {
  DELETE_FILE_URL,
  GET_DOMAINS_URL,
  GET_FILES_URL,
  TRANSCRIBE_AUDIO,
  UPDATE_FILE_URL,
  UPLOAD_FILE_URL,
} from "./url";

class FileService {
  getDomains = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_DOMAINS_URL)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getAllFiles = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_FILES_URL)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  deleteFile = (fileId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(DELETE_FILE_URL.format(fileId))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  updateFile = (file) =>
    new Promise((resolve, reject) => {
      axios
        .put(UPDATE_FILE_URL.format(file["id"]), { name: file["name"] })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  uploadFile = (file, domainId, onUploadProgress) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("domain_id", domainId);
      const config = {
        onUploadProgress: onUploadProgress,
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios
        .postForm(UPLOAD_FILE_URL, formData, config)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  transcribeAudio = (file, onUploadProgress) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        onUploadProgress: onUploadProgress,
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios
        .postForm(TRANSCRIBE_AUDIO, formData, config)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const fileService = new FileService();

export default fileService;
