/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Error404View from "./views/404/Error404View";
import Account from "./views/Account";
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";
import LoginGuard from "./components/LoginGuard";
import Files from "./views/Files";
import DashboardLayout from "./views/DashboardLayout";
// import Chats from "./views/Chats";
import Login from "./views/Auth/Login";
import Authenticate from "./views/Auth/Authenticate";
import EmailVerification from "./views/Auth/EmailVerification";
import Sessions from "./views/AskAI/Sessions";
import Messages from "./views/AskAI/Messages";
import Followup from "./views/AskAI/Followup";
import NewSession from "./views/AskAI/NewSession";
import {
  FILES_URL,
  SESSIONS_URL,
  AUTHENTICATE_URL,
  LOGIN_URL,
  ERROR_URL,
  EMAIL_VERIFICATION_URL,
  ACCOUNT_KEY_URL,
  Q_A_MODE_URL,
  CHECKLIST_DIAGRAM_MODE_URL,
  DATA_ANALYSIS_MODE_URL,
} from "./utils/routes";
import QnA from "./views/AskAI/QnA/QnA";
import ChecklistDiagram from "./views/AskAI/ChecklistDiagram/ChecklistDiagram";
import DataAnalysis from "./views/AskAI/DataAnalysis/DataAnalysis";
import DataAnalysisMessages from "./views/AskAI/DataAnalysis/Messages/Messages";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to={Q_A_MODE_URL} />,
  },
  {
    exact: true,
    path: ERROR_URL,
    component: () => <Error404View />,
  },
  ...(process.env.REACT_APP_ENV === "staging"
    ? [
        {
          exact: true,
          path: ACCOUNT_KEY_URL,
          guard: LoginGuard,
          component: () => <Account />,
        },
      ]
    : []),
  {
    exact: true,
    path: LOGIN_URL,
    guard: LoginGuard,
    component: () => <Login />,
  },
  {
    exact: true,
    path: EMAIL_VERIFICATION_URL,
    guard: LoginGuard,
    component: () => <EmailVerification />,
  },
  {
    exact: true,
    path: AUTHENTICATE_URL,
    component: () => <Authenticate />,
  },
  {
    path: "",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: Q_A_MODE_URL,
        component: () => <QnA />,
      },
      {
        exact: true,
        path: CHECKLIST_DIAGRAM_MODE_URL,
        component: () => <ChecklistDiagram />,
      },
      {
        exact: true,
        path: DATA_ANALYSIS_MODE_URL,
        component: () => <DataAnalysis />,
      },
      // {
      //   exact: true,
      //   path: SESSIONS_URL,
      //   component: () => <Sessions />,
      // },
      // {
      //   exact: true,
      //   path: NEW_SESSION_URL,
      //   component: () => <NewSession />,
      // },
      {
        exact: true,
        path: "/qna/sessions/:sessionId",
        component: () => <Messages />,
      },
      {
        exact: true,
        path: "/qna/sessions/:sessionId/messages/:messageId",
        component: () => <Followup />,
      },
      {
        exact: true,
        path: "/data-analysis/sessions/:sessionId",
        component: () => <DataAnalysisMessages />,
      },
      {
        exact: true,
        path: FILES_URL,
        component: () => <Files />,
      },
    ],
  },
  {
    component: () => <Redirect to={ERROR_URL} />,
  },
];

const renderRoutes = (routes) => (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Guard>
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
