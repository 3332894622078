import React from "react";
import {
  Box,
  IconButton,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import { ArrowBackIosRounded } from "@mui/icons-material";
import { Actions } from "../Header";
import { SheetSelection } from "../../../AskAI/DataAnalysis/Messages/SourceDialog";

function Header({
  file,
  selectedCSV,
  handleChangeCSV,
  handleDeleteFile,
  handleUpdateFile,
  handleGoBack,
}) {
  const { name, csv_file_map } = file;
  const csvList = Object.keys(csv_file_map);

  return (
    <Box
      px={{ xs: 2, sm: 3 }}
      py={2}
      display={"flex"}
      alignItems={"center"}
      sx={{
        background: "#323639",
        boxShadow:
          "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
      }}
      width={"100%"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"calc(100% - 40px)"}
        mr={1}>
        <IconButton onClick={handleGoBack} sx={{ mr: 1, color: "white" }}>
          <ArrowBackIosRounded fontSize="small" />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ color: "white" }}>
          {name}
        </Typography>
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <SheetSelection
          mode="light"
          csvList={csvList}
          selectedCSV={selectedCSV}
          handleChangeCSV={handleChangeCSV}
        />
        <Actions
          file={file}
          handleDeleteFile={handleDeleteFile}
          handleUpdateFile={handleUpdateFile}
        />
      </Box>
    </Box>
  );
}

export default Header;
