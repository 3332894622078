import React, { Suspense } from "react";
import { Box, Typography } from "@mui/material";
import * as Icons from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

export const DynamicIcon = ({ iconName, sx }) => {
  const Icon = Icons[iconName];
  return <Icon sx={sx} />;
};

export function PDFIcon({
  wrapperStyle = { borderRadius: 12.5, padding: "5px 3px" },
  textStyle = { fontSize: 7 },
}) {
  return (
    <Box
      sx={{
        ...wrapperStyle,
        background: "#EB4435",
      }}
    >
      <Typography
        fontWeight={700}
        fontFamily={"Roboto Condensed"}
        sx={{
          ...textStyle,
          color: "#ffffff",
          lineHeight: "normal",
        }}
      >
        PDF
      </Typography>
    </Box>
  );
}

export function Vector({ border, bgcolor }) {
  const id = `paint0_linear_${uuidv4()}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="198"
      height="158"
      viewBox="0 0 198 158"
      fill="none"
    >
      <path
        d="M0.622952 24.7506V24.75C0.622952 11.4073 11.3481 0.5 24.5903 0.5H70.1563C76.5389 0.5 82.682 3.04515 87.2607 7.65366L104.597 25.1024L104.744 25.25H104.952H173.313C186.558 25.25 197.403 36.1606 197.403 49.5V133.25C197.403 146.589 186.558 157.5 173.313 157.5H24.5903C11.3448 157.5 0.5 146.589 0.5 133.25L0.622952 24.7506Z"
        fill={`url(#${id})`}
        stroke={border}
      />
      <defs>
        <linearGradient
          id={id}
          x1="0"
          y1="0"
          x2="275.22"
          y2="140.623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={bgcolor} />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function ChatRoundedGradient() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_9874_12018)">
        <path
          d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM7 9H17C17.55 9 18 9.45 18 10C18 10.55 17.55 11 17 11H7C6.45 11 6 10.55 6 10C6 9.45 6.45 9 7 9ZM13 14H7C6.45 14 6 13.55 6 13C6 12.45 6.45 12 7 12H13C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14ZM17 8H7C6.45 8 6 7.55 6 7C6 6.45 6.45 6 7 6H17C17.55 6 18 6.45 18 7C18 7.55 17.55 8 17 8Z"
          fill="url(#paint0_linear_9874_12018)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9874_12018"
          x1="21.9722"
          y1="22"
          x2="-2.14318"
          y2="12.2925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#732668" />
          <stop offset="0.5" stop-color="#402673" />
          <stop offset="1" stop-color="#2F2673" />
        </linearGradient>
        <clipPath id="clip0_9874_12018">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SnippetFolderRoundedGradient() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.88 10.5L17.5 12.12V15.5H14.5V10.5H15.88ZM22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.58 4.59L12 6H20C21.1 6 22 6.9 22 8ZM19 11.91C19 11.64 18.89 11.39 18.71 11.2L16.8 9.29C16.61 9.11 16.35 9 16.09 9H14C13.45 9 13 9.45 13 10V16C13 16.55 13.45 17 14 17H18C18.55 17 19 16.55 19 16V11.91Z"
        fill="url(#paint0_linear_9874_18004)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9874_18004"
          x1="21.9722"
          y1="20"
          x2="-0.389202"
          y2="8.74823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#732668" />
          <stop offset="0.5" stop-color="#402673" />
          <stop offset="1" stop-color="#2F2673" />
        </linearGradient>
      </defs>
    </svg>
  );
}
