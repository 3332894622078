import React, { useState } from "react";
import {
  ContentCopyOutlined,
  ContentCopyRounded,
  CopyAllOutlined,
  IosShareOutlined,
  LinkRounded,
  ShareOutlined,
  ShareRounded,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  useTheme,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import { useSnackbar } from "notistack";

function ShareMessage({ message }) {
  const { enqueueSnackbar } = useSnackbar();
  const { answer, query } = message;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyMessage = () => {
    handleCopy(answer["content_object"]["text"], "Copied message to clipboard");
  };

  const handleCopyMessageLink = () => {
    handleCopy(
      `${window.location.origin}${window.location.pathname}?messageId=${query["message_id"]}`,
      "Copied message link to clipboard"
    );
  };

  const handleCopy = (content, toastMessage) => {
    window.navigator.clipboard.writeText(content);
    enqueueSnackbar(toastMessage);
    handleClose();
  };

  return (
    <Box ml={1}>
      <Tooltip title="Share">
        <IconButton
          size="small"
          sx={{ color: theme.palette.action.active }}
          onClick={handleClick}>
          <IosShareOutlined fontSize="small" sx={{ fontSize: 18 }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        autoFocus={false}
        disableAutoFocusItem
        slotProps={{ paper: { sx: { borderRadius: 2 } } }}>
        <MenuItem onClick={handleCopyMessageLink}>
          <ListItemIcon>
            <LinkRounded sx={{ fontSize: 18 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: "body2" }}>
            Copy Message Link
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCopyMessage}>
          <ListItemIcon>
            <ContentCopyRounded sx={{ fontSize: 18 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: "body2" }}>
            Copy Message Content
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default ShareMessage;
