import fileService from "../api/fileService";
import {
  deleteFileSuccess,
  getFilesSuccess,
  updateFileSuccess,
  uploadFileSuccess,
  getFilesFailure,
  getFilesInitiate,
  getDomainsFailure,
  getDomainsSuccess,
  getDomainsInitiate,
} from "../events/filesEvents";
import {
  setSelectedDomainSuccess,
  setSelectedFilesSuccess,
} from "../events/sessionEvents";
import {
  getSelectedDomainLocalStorage,
  getSelectedFilesLocalStorage,
} from "../storage/localStorage";

export function getDomains() {
  return async (dispatch) => {
    try {
      dispatch(getDomainsInitiate());
      const response = await fileService.getDomains();
      const domains = response["domains"] || [];
      dispatch(getDomainsSuccess(domains));
      const selectedDomainFromLocalStorage = getSelectedDomainLocalStorage();
      let selectedDomain = selectedDomainFromLocalStorage;
      if (!selectedDomainFromLocalStorage && domains.length > 0) {
        selectedDomain = domains[0];
      }
      dispatch(setSelectedDomainSuccess(selectedDomain));
      return domains;
    } catch (error) {
      dispatch(getDomainsFailure());
      throw error;
    }
  };
}

export function getAllFiles() {
  return async (dispatch) => {
    try {
      dispatch(getFilesInitiate());
      const response = await fileService.getAllFiles();
      const files = response["files"] || [];
      dispatch(getFilesSuccess(files));
      return files;
    } catch (error) {
      dispatch(getFilesFailure());
      throw error;
    }
  };
}

export function deleteFile(fileId) {
  return async (dispatch) => {
    try {
      await fileService.deleteFile(fileId);
      dispatch(deleteFileSuccess(fileId));
    } catch (error) {
      throw error;
    }
  };
}

export function updateFile(file) {
  return async (dispatch) => {
    try {
      await fileService.updateFile(file);
      dispatch(updateFileSuccess(file));
    } catch (error) {
      throw error;
    }
  };
}

export function uploadFile(file, domainId, onUploadProgress) {
  return async (dispatch) => {
    try {
      const response = await fileService.uploadFile(
        file,
        domainId,
        onUploadProgress
      );
      dispatch(uploadFileSuccess(response));
    } catch (error) {
      throw error;
    }
  };
}

export async function transcribeAudio(file, onUploadProgress) {
  try {
    const response = await fileService.transcribeAudio(file, onUploadProgress);
    return response;
  } catch (error) {
    throw error;
  }
}
