import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Grid, Skeleton, useTheme, Chip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { getUserDetails, getAccountDetails, setUser } from "../../../core/storage/localStorage";
import Query from "../Messages/Query";
import { getPromptQuestions } from "../../../core/repo/sessionRepo";
import { useDispatch, useSelector } from "react-redux";
import { createSession } from "../../../core/repo/sessionRepo";
import { useHistory } from "react-router-dom";
import { upgradeRequest } from "../../../core/repo/accountRepo";
import { Autorenew } from "@mui/icons-material";
import { getColorShades, getGreeting, getRandomBgImage, toTitleCase } from "../../../utils/utils";
import ChatModes, { getChatModeObj } from "../components/ChatModes";
import { SESSIONS_URL } from "../../../utils/routes";
import Loader from "../../../components/Loader";

const useStyles = makeStyles()((theme, { showPromptQuestions }, classes) => ({
  container: showPromptQuestions
    ? {
        borderRadius: 32,
        border: "1px solid rgba(189, 189, 189, 0.25)",
        background: "rgba(255, 255, 255, 0.54)",
        backdropFilter: "blur(30px)",
        padding: "32px 24px 24px 24px",
        maxWidth: 900,
        width: "100%",
      }
    : {
        borderRadius: 32,
        padding: "32px 24px",
        background: theme.palette.paper,
      },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  chip: {
    padding: "4px 10px",
    border: `1px solid #BDBDBD`,
    borderRadius: 100,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
}));

function NewSession() {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = getUserDetails();
  const { upgrade_request } = user;
  const account = getAccountDetails();
  const { plan, is_org } = account;
  const { completedFiles, isFetched } = useSelector((state) => state.files);
  const [showUpgradeRequest, setShowUpgradeRequest] = useState(plan === "free" && !upgrade_request);
  const [isUpgradeRequestLoading, setIsUpgradeRequestLoading] = useState(false);

  const handleUpgradeClick = async () => {
    setIsUpgradeRequestLoading(true);
    try {
      await dispatch(upgradeRequest());
      setUser({ ...user, isBannerOpen: false, upgrade_request: true });
      window.dispatchEvent(new Event("storage"));
      setShowUpgradeRequest(false);
      // setTimeout(() => {}, 5000);
    } catch (err) {
      console.log(err);
    }
    setIsUpgradeRequestLoading(false);
  };

  useEffect(() => {
    if (isFetched && completedFiles.length === 0) {
      history.push(SESSIONS_URL);
    }
  }, [completedFiles]);

  return (
    <Box
      p={{ xs: 1, md: 3 }}
      borderRadius={6}
      sx={{
        height: "100%",
        width: "100%",
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), url(${getRandomBgImage()}) lightgray 50% / cover no-repeat`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {isFetched ? (
        <>
          {!is_org && (
            <Chip
              icon={
                isUpgradeRequestLoading ? (
                  <Autorenew fontSize="small" sx={{ color: "white !important" }} />
                ) : null
              }
              onClick={handleUpgradeClick}
              variant="filled"
              label={
                isUpgradeRequestLoading
                  ? "Please wait..."
                  : showUpgradeRequest
                  ? "🚀 Upgrade"
                  : "Upgrade requested"
              }
              sx={{
                background: showUpgradeRequest ? theme.palette.light : "#757575",
                color: "white",
                marginBottom: 2,
              }}
            />
          )}
          <StartSessionContainer showPromptQuestions={true} />
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
}

export function StartSessionContainer({ selectedDomain }) {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryRef = useRef();
  const [user, setUser] = useState(() => getUserDetails());

  useEffect(() => {
    const handleStorage = () => {
      const user = getUserDetails();
      setUser(user);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  //TODO: add files to params.
  async function submitQuery(query) {
    try {
      const response = await dispatch(
        createSession(user["id"], selectedDomain ? selectedDomain["id"] : null, "qna")
      );
      history.push({
        pathname: `/qna/sessions/${response["id"]}`,
        search:
          "?" +
          new URLSearchParams({
            query,
          }).toString(),
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Box p={2} borderRadius={6} sx={{ background: theme.palette.paper }} mb={6}>
      <Typography variant="h6" mb={0.5}>
        Hey {user ? toTitleCase(user["name"]) : ""}! Start a new session by asking a question below
        👇
      </Typography>
      <Box mb={3} />
      <Query ref={queryRef} handleStreamMessages={submitQuery} />
    </Box>
  );
}

export default NewSession;
