import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
// import { zoomPlugin } from "@react-pdf-viewer/zoom";
// import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { printPlugin } from "@react-pdf-viewer/print";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
// Import styles
import "@react-pdf-viewer/print/lib/styles/index.css";
import "./index.css";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import { getFilePlugin } from "@react-pdf-viewer/get-file";

function PDFView({
  source = { page: null, coordinates: null },
  currentPage,
  file,
  scale = 1,
  handlePagesChange,
  zoomPluginInstance,
  pageNavigationPluginInstance,
  printPluginInstance = printPlugin(),
  getFilePluginInstance = getFilePlugin(),
  setCurrentPage,
}) {
  let { page, coordinates } = source;
  if (page > 0) {
    page = page - 1;
  }
  const [isFileLoading, setIsFileLoading] = useState(true);
  const { jumpToPage } = pageNavigationPluginInstance;
  const [areas, setAreas] = useState([]);

  const pageLayout = {
    transformSize: ({ size }) => ({
      height: size.height + 8,
      width: size.width + 8,
    }),
  };

  useEffect(() => {
    if (page !== null && page !== undefined && coordinates !== null) {
      let parsedCoordinates = [5, 5, 90, 90];
      try {
        parsedCoordinates = JSON.parse(coordinates);
      } catch (err) {}
      setAreas([
        {
          pageIndex: page,
          height: parsedCoordinates[3],
          width: parsedCoordinates[2],
          left: parsedCoordinates[0],
          top: parsedCoordinates[1],
        },
      ]);
    }
    if (currentPage) {
      jumpToPage(currentPage);
    } else if (page !== null && page !== undefined) {
      jumpToPage(page);
    }
  }, [isFileLoading, page, currentPage, file, source]);

  const renderHighlights = (props) => (
    <div>
      {areas
        .filter((area) => area.pageIndex === props.pageIndex)
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area"
            style={Object.assign(
              {},
              {
                background: "yellow",
                opacity: 0.1,
              },
              props.getCssProperties(area, props.rotation)
            )}
          />
        ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const handleDocumentLoad = (e) => {
    if (handlePagesChange) handlePagesChange(e.doc.numPages);
    setIsFileLoading(!isFileLoading);
  };

  const onPageChange = (e) => {
    const { currentPage } = e;
    if (setCurrentPage) {
      setCurrentPage(currentPage);
    }
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Viewer
        onPageChange={onPageChange}
        onDocumentLoad={handleDocumentLoad}
        plugins={[
          highlightPluginInstance,
          pageNavigationPluginInstance,
          zoomPluginInstance,
          printPluginInstance,
          getFilePluginInstance,
        ]}
        defaultScale={scale}
        fileUrl={file["file_url"]}
        pageLayout={pageLayout}
        renderLoader={(percentages) => (
          <CircularProgressWithLabel value={Math.round(percentages)} />
        )}
      />
    </Worker>
  );
}

export default PDFView;
