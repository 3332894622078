import { useEffect, useRef, useState } from "react";

function useScrollHeader(threshold = 50) {
  const [showHeader, setShowHeader] = useState(false);
  const containerRef = useRef();

  const handleScroll = () => {
    if (containerRef.current) {
      setShowHeader(containerRef.current.scrollTop > threshold);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      handleScroll();
      containerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [threshold]);

  return { containerRef, showHeader };
}

export default useScrollHeader;
