import React from "react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { AutoModeRounded } from "@mui/icons-material";
import SkeletonLoader from "../../../components/SkeletonLoader";
import CustomTimlineItem from "../../../components/CustomTimlineItem";
import { Timeline } from "@mui/lab";
import { GradientIconAccent } from "../../../components/GradientIcon";
import Question from "./Message/Question";
import RenderMarkdown from "./Message/RenderMarkdown";

function StreamMessage({
  message,
  numOfMessages,
  tempQandA,
  isMetadataLoading,
}) {
  return (
    <>
      {message ? (
        <Timeline
          sx={{
            mt:
              message["query"]["threads"] &&
              message["query"]["threads"].length > 0
                ? -3
                : 2,
          }}>
          <CustomTimlineItem>
            <Content
              tempQandA={tempQandA}
              message={message}
              numOfMessages={numOfMessages}
              isMetadataLoading={isMetadataLoading}
            />
          </CustomTimlineItem>
        </Timeline>
      ) : (
        <Content
          tempQandA={tempQandA}
          message={message}
          numOfMessages={numOfMessages}
          isMetadataLoading={isMetadataLoading}
        />
      )}
    </>
  );
}

function Content({ message, numOfMessages, tempQandA, isMetadataLoading }) {
  function getLoaderMessage() {
    switch (tempQandA["chatMode"]) {
      case "fetch_checklist_diagram": {
        return "Finding the entity";
      }

      case "data_analysis": {
        return "Analyzing";
      }

      case "general_qna": {
        return "Retreiving Answer";
      }

      default: {
        return "Retreiving Answer";
      }
    }
  }

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${
          message || numOfMessages === 0 ? 333 : 303
        }px)`,
      }}>
      {!message && numOfMessages !== 0 && <Divider sx={{ marginY: 6 }} />}
      <Question query={{ content_object: { text: tempQandA["query"] } }} />
      <Box display={"flex"} justifyContent={"flex-end"} mb={1.5}>
        <Skeleton variant="rounded" width={180} height={24} />
      </Box>
      <Box
        display="flex"
        alignItems={tempQandA["response"] ? "start" : "center"}>
        <GradientIconAccent
          Icon={AutoModeRounded}
          className={"iconLoading"}
          sx={{ fontSize: 32 }}
        />
        <Box mr={2} />
        {tempQandA["response"] ? (
          <Box>
            <RenderMarkdown message={tempQandA["response"]} />
          </Box>
        ) : (
          <Typography>
            {getLoaderMessage()}
            <span className="loadingStyle"></span>
          </Typography>
          // <SkeletonLoader />
        )}
      </Box>
      {isMetadataLoading && (
        <Box
          sx={{ mb: 5, mt: 5, pl: 6 }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Skeleton variant="circular" width={30} height={30} />
            <Box mr={1} />
            <Skeleton variant="circular" width={30} height={30} />
            <Box mr={1} />
            <Skeleton variant="circular" width={30} height={30} />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Skeleton variant="rounded" width={180} height={24} />
            <Box mr={3} />
            <Skeleton variant="rounded" width={180} height={24} />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default StreamMessage;
