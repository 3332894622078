import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import FilesList from "./FilesList/FilesList";
import EmptyFilesScreen from "../EmptyFilesScreen";
import FileUploadButton from "../FileUploadButton";

const useStyles = makeStyles()((theme) => ({
  filesContainer: {
    padding: 24,
    paddingTop: 0,
    maxWidth: 908,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
    },
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function Results({ files, selectedDomain, handleSelectFile, handleDeleteFile, handleUpdateFile }) {
  const { classes } = useStyles();

  return (
    <Box className={classes.filesContainer}>
      <Box display={"flex"} justifyContent={"space-between"} mb={7}>
        <Box>
          <Typography variant="h4">
            <span className={classes.gradientText}>Files</span>
          </Typography>
          <Box mb={1} />
          {/* <Typography variant="body2" color={"textSecondary"}>
            Your complete knowledge hub.
          </Typography> */}
        </Box>
        <FileUploadButton />
      </Box>
      {files.length === 0 ? (
        <EmptyFilesScreen />
      ) : (
        <FilesList
          files={files}
          selectedDomain={selectedDomain}
          handleSelectFile={handleSelectFile}
          handleDeleteFile={handleDeleteFile}
          handleUpdateFile={handleUpdateFile}
        />
      )}
    </Box>
  );
}

export default Results;
