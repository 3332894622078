import React from "react";
import { Redirect } from "react-router-dom";
import { getAccountDetails } from "../core/storage/localStorage";
import { ACCOUNT_KEY_URL } from "../utils/routes";

function GuestGuard({ children }) {
  const account = getAccountDetails();
  if (!account) {
    return <Redirect to={ACCOUNT_KEY_URL} />;
  }

  return children;
}

export default GuestGuard;
