import axios from "./axios";
import { GET_DOMAINS_URL } from "./url";

class DomainService {
  getDomains = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_DOMAINS_URL)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const domainService = new DomainService();

export default domainService;
