import React, { useState } from "react";
import Page from "../../components/Page";
import { makeStyles } from "tss-react/mui";
import {
  Typography,
  Box,
  InputBase,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { sendMagicLink } from "../../core/repo/accountRepo";
import Logo from "../../components/Logo";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { EMAIL_VERIFICATION_URL } from "../../utils/routes";

const useStyles = makeStyles()((theme) => ({
  root: {
    background: "#121417",
    minHeight: "100vh",
    minHeight: "100svh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    border: "0.763px solid rgba(255, 255, 255, 0.23)",
    padding: 12,
    borderRadius: 8,
    background: "rgba(255, 255, 255, 0.06)",
    "&:hover": {
      borderColor: "whitesmoke",
    },
  },
  container: {
    width: 400,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 16,
    background: "rgba(255, 255, 255, 0.02)",
    boxShadow: "0px 8px 30px 0px rgba(0, 0, 0, 0.12)",
    backdropFilter: "blur(12px)",
    [theme.breakpoints.up("sm")]: {
      padding: 24,
      width: 420,
    },
  },
  gradientText: {
    background: theme.palette.dark,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textDecoration: "underline",
    textDecorationColor: "#EF8FE2",
    textUnderlineOffset: 4,
    cursor: "pointer",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email is required."),
});

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  async function handleSendEmailMagicLink(values) {
    setIsLoading(true);
    try {
      const { email } = values;
      await dispatch(sendMagicLink(email));
      enqueueSnackbar("Verification link has been sent to your email.", {
        variant: "success",
      });
      history.push({
        pathname: EMAIL_VERIFICATION_URL,
        search: "?" + new URLSearchParams({ email: email }).toString(),
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to send verfication link to your email.", {
        variant: "error",
      });
    }
    setIsLoading(false);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSendEmailMagicLink,
  });

  return (
    <Page className={classes.root} title="Login">
      <Box display={"flex"} alignItems={"center"} mb={5}>
        <Logo isBoxShadow={false} />
        <Box mr={1} />
        <Typography variant="h2" sx={{ color: "white", fontSize: "26.732px" }}>
          Raven AI
        </Typography>
      </Box>
      <Box
        mb={5}
        width={400}
        height={2}
        sx={{
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%, rgba(255, 255, 255, 0.00) 100%)",
        }}></Box>
      <Box className={classes.container}>
        <Typography variant="h4" sx={{ color: "white", textAlign: "center" }}>
          Enter your email
          <br /> to get started
        </Typography>
        <Box mb={5} />
        <form style={{ width: "100%" }}>
          <InputBase
            fullWidth
            name="email"
            placeholder="example@email.com"
            className={classes.textField}
            inputProps={{ style: { color: "rgba(255, 255, 255, 0.50)" } }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {formik.touched.email && formik.errors.email && (
            <FormHelperText error sx={{ ml: 1 }}>
              {formik.errors.email}
            </FormHelperText>
          )}
          <Box mb={3} />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={isLoading}
            onClick={formik.handleSubmit}
            sx={{
              background:
                "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%) !important",
            }}>
            {isLoading ? (
              <CircularProgress sx={{ color: "white" }} size={26.5} />
            ) : (
              "Continue"
            )}
          </Button>
        </form>
      </Box>
    </Page>
  );
}

export default Login;
