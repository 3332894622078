import React from "react";
import { Box, Typography } from "@mui/material";
import FileUploadButton from "./FileUploadButton";

function Header({ showHeader }) {
  return (
    <Box
      visibility={showHeader ? "visible" : "hidden"}
      p={2}
      display={"flex"}
      justifyContent={"space-between"}>
      <Typography variant="h6">Files</Typography>
      <FileUploadButton />
    </Box>
  );
}

export default Header;
