import React, { useState } from "react";
import { Box, Typography, Chip, Tooltip, useTheme } from "@mui/material";
import {
  ExpandLessRounded,
  ExpandMoreRounded,
  Check,
} from "@mui/icons-material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  icon: {
    margin: "0px 5px 0px -6px !important",
  },
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
}));

function AnalysisLogs({ answer }) {
  const theme = useTheme();
  const { classes } = useStyles();
  const [areLogsOpen, setAreLogsOpen] = useState(false);

  const steps =
    answer["content_object"]["tabular_data_result"] &&
    answer["content_object"]["tabular_data_result"]["process_steps_log"]
      ? answer["content_object"]["tabular_data_result"]["process_steps_log"][
          "steps"
        ]
      : [];

  const toggleLogs = () => {
    setAreLogsOpen((prev) => !prev);
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        mb={2}>
        <Chip
          classes={{ icon: classes.icon }}
          label={"Analysis"}
          icon={areLogsOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          onClick={toggleLogs}
          sx={{
            background: areLogsOpen ? "#E8EAF6" : "transparent",
            flexDirection: "row-reverse",
            color: theme.palette.text.secondary,
            fontWeight: 500,
          }}
        />
      </Box>
      {areLogsOpen && <Logs steps={steps} />}
    </>
  );
}

function Logs({ steps }) {
  const { classes } = useStyles();

  const processedSteps = steps.map((step) => {
    const [title, value] = step.split(":", 2);
    return { title: title.trim(), value: value.trim() };
  });

  return (
    <Box pb={4} pl={6}>
      <Timeline sx={{ padding: 0, margin: 0 }}>
        {processedSteps.map((step, i) => {
          const { title, value } = step;
          return (
            <TimelineItem
              key={i}
              classes={{
                missingOppositeContent: classes.missingOppositeContent,
              }}
              sx={{ minHeight: i === processedSteps.length - 1 ? 0 : 70 }}>
              <TimelineSeparator>
                <Tooltip title={title}>
                  <TimelineDot sx={{ padding: 0, marginTop: "9px" }}>
                    <Check sx={{ fontSize: 10 }} />
                  </TimelineDot>
                </Tooltip>
                {i !== processedSteps.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ width: "100%" }}>
                <Typography variant="subtitle2" color={"textSecondary"}>
                  {value}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
}

export default AnalysisLogs;
