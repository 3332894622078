import React from "react";
import CSVViewer from "../../Files/ViewFile/CSVViewer";

function HighlightedCSV({ source }) {
  const columns = source["columns"].map((column) =>
    column.trim().toLowerCase()
  );
  const rows = source["selected_row_ids"].map((row) => row.toString());
  return (
    <CSVViewer
      file={{ file_url: source["csv_file_path"] }}
      highlightRows={rows}
      highlightColumns={columns}
    />
  );
}

export default HighlightedCSV;
