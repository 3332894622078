import React, { Fragment } from "react";
import { AddRounded, ChevronRightRounded } from "@mui/icons-material";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFileIconImage } from "../../../Files/FilesView/Results/FilesList/GridView";
import { formatDate } from "../../../../utils/utils";
import { createSession } from "../../../../core/repo/sessionRepo";
import { getUserDetails } from "../../../../core/storage/localStorage";
import { useHistory } from "react-router-dom";
import DataAnalysisFileDialog from "./DataAnalysisFileDialog";
import { toTitleCase } from "../../../../utils/utils";

function StartSession() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = getUserDetails();
  const { selectedDomain } = useSelector((state) => state.domain);
  const { completedFiles } = useSelector((state) => state.files);
  const excelFilesInSelectedDomain = completedFiles
    .filter((f) => !selectedDomain || f["domain_id"] === selectedDomain["id"])
    .filter((file) => ["xls", "xlsx"].includes(file["file_type"]));

  async function handleCreateSession(file) {
    try {
      const session = await dispatch(
        createSession(
          user["id"],
          selectedDomain ? selectedDomain["id"] : null,
          "data_analysis",
          file["id"]
        )
      );
      history.push(`/data-analysis/sessions/${session["id"]}`);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    excelFilesInSelectedDomain.length > 0 && (
      <Box
        p={2}
        borderRadius={6}
        mb={7}
        sx={{ background: theme.palette.paper }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={3}>
          <Typography variant="h6">
            Hey {user ? toTitleCase(user["name"]) : ""}! Start by asking a
            question on tabular data 👉
          </Typography>
          <DataAnalysisFileDialog
            handleCreateSession={handleCreateSession}
            excelFilesInSelectedDomain={excelFilesInSelectedDomain}
          />
        </Box>
        <Box>
          <Typography variant="overline" color={"textSecondary"}>
            Recent files
          </Typography>
          <Box
            mt={0.5}
            borderRadius={3}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              background: "white",
            }}>
            {excelFilesInSelectedDomain.map((file, index) => {
              const { id, name, file_type, created_at } = file;
              return (
                <Fragment key={id}>
                  <Box
                    pl={2}
                    py={1.25}
                    pr={1}
                    display={"flex"}
                    alignItems={"center"}>
                    <img
                      src={getFileIconImage(file_type)}
                      width={30}
                      height={30}
                      alt={name}
                    />
                    <Box width={"100%"} ml={1.5} mr={3}>
                      <Typography
                        variant="body2"
                        noWrap
                        textOverflow={"ellipsis"}>
                        {name}
                      </Typography>
                      <Typography variant="caption" color={"textSecondary"}>
                        {formatDate(created_at)}
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      endIcon={<ChevronRightRounded fontSize="small" />}
                      sx={{ minWidth: 150, color: theme.palette.action.active }}
                      onClick={() => handleCreateSession(file)}>
                      Start Session
                    </Button>
                  </Box>
                  {index !== excelFilesInSelectedDomain.length - 1 && (
                    <Divider sx={{ ml: 7.25 }} />
                  )}
                </Fragment>
              );
            })}
          </Box>
        </Box>
      </Box>
    )
  );
}

export default StartSession;
