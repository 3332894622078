import React, { useState } from "react";
import {
  Button,
  Box,
  useTheme,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  ListItemText,
} from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GradientIconAccent } from "../../../components/GradientIcon";
import {
  getSessionMessagesSuccess,
  setSelectedDomainSuccess,
} from "../../../core/events/sessionEvents";
import { DynamicIcon } from "../../../components/CustomIcons";
import { SESSIONS_URL } from "../../../utils/routes";

const useStyles = makeStyles()((theme) => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function NewChatButton({ isExpand, onClose }) {
  const history = useHistory();
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { completedFiles, domains } = useSelector((state) => state.files);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleNewChatClick = () => {
    history.push(`/sessions/new-session`);
    dispatch(getSessionMessagesSuccess([]));
    if (onClose) onClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDomain = (domain) => {
    dispatch(setSelectedDomainSuccess(domain));
    handleClose();
    history.push(SESSIONS_URL);
  };

  return (
    domains.length > 0 && (
      <Box px={2} py={1}>
        <Tooltip title="Start Session" disableHoverListener={isExpand}>
          <Button
            onClick={handleClick}
            classes={{ startIcon: !isExpand ? classes.startIcon : null }}
            fullWidth
            variant="outlined"
            color="secondary"
            disabled={completedFiles.length === 0}
            startIcon={
              completedFiles.length === 0 ? (
                <AddRounded fontSize="small" />
              ) : (
                <GradientIconAccent Icon={AddRounded} sx={{ fontSize: 20 }} />
              )
            }
            sx={{
              borderRadius: 2,
              minWidth: 30,
              padding: 1.2,
              background: "#fff",
              borderColor: theme.palette.accent,
            }}>
            <span className={classes.gradientText}>
              {isExpand ? "Start Session" : ""}
            </span>
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: { sx: { borderRadius: 2, minWidth: 100, ml: 1 } },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}>
          {domains.map((d) => {
            const { id, domain, icon } = d;
            return (
              <MenuItem key={id} onClick={() => handleSelectDomain(d)}>
                <ListItemIcon>
                  <DynamicIcon iconName={icon} />
                </ListItemIcon>
                <ListItemText>{domain}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    )
  );
}

export default NewChatButton;
