import React, { useEffect, useRef, useState } from "react";
import Page from "../../../components/Page";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Results from "./Results/Results";
import NoSessions from "./NoSessions";
import Header from "./Header";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import { StartSessionContainer } from "../NewSession";
import { useHistory, useLocation } from "react-router-dom";
import { NEW_SESSION_URL } from "../../../utils/routes";
import Filters from "./Filters";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
  },
  flexBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function Sessions({ mode }) {
  const sessionsRef = useRef();
  const { sessions, isSessionsLoading, isSessionFetched } = useSelector(
    (state) => state.session
  );
  const { selectedDomain } = useSelector((state) => state.domain);
  const { completedFiles } = useSelector((state) => state.files);
  const history = useHistory();
  const location = useLocation();
  const [filteredSessions, setFilteredSessions] = useState([]);

  useEffect(() => {
    setFilteredSessions(sessions);
  }, [selectedDomain, sessions]);

  // preserves scroll position
  useEffect(() => {
    if (isSessionFetched && location.state && sessionsRef.current) {
      sessionsRef.current.scrollTop = location.state.sessionsScrollPosition;
    }
  }, [isSessionFetched]);

  const handleSessionClick = (id) => {
    const scrollPosition = sessionsRef.current
      ? sessionsRef.current.scrollTop
      : 0;

    //pass current scroll position in the state
    history.push({
      pathname: `/${mode}/sessions/${id}`,
      state: { sessionsScrollPosition: scrollPosition },
    });
  };

  const handleQueryChange = (query) => {
    let filteredSessions = sessions;
    if (query) {
      filteredSessions = filteredSessions.filter((s) =>
        s["title"].toLowerCase().includes(query.toLowerCase())
      );
    }
    setFilteredSessions(filteredSessions);
  };

  return (
    <Box>
      {sessions.length === 0 &&
      isSessionFetched &&
      !isSessionsLoading &&
      completedFiles.length === 0 ? (
        <NoSessions mode={mode} />
      ) : (
        <>
          {sessions.length > 0 && (
            <Filters
              sessions={filteredSessions}
              handleQueryChange={handleQueryChange}
            />
          )}
          <Results
            sessions={filteredSessions}
            handleSessionClick={handleSessionClick}
          />
        </>
      )}
    </Box>
  );
}

export default Sessions;
