import React from "react";
import { Box, Divider, Typography, Skeleton } from "@mui/material";
import { GradientIconAccent } from "../../../../components/GradientIcon";
import Question from "../../components/Message/Question";
import { AutoModeRounded } from "@mui/icons-material";

function MessageLoader({ numOfMessages, query }) {
  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${numOfMessages === 0 ? 333 : 303}px)`,
      }}>
      {numOfMessages !== 0 && <Divider sx={{ marginY: 6 }} />}
      <Question query={{ content_object: { text: query } }} />
      <Box display={"flex"} justifyContent={"flex-end"} mb={1.5}>
        <Skeleton variant="rounded" width={180} height={24} />
      </Box>
      <Box display="flex" alignItems={"center"}>
        <GradientIconAccent
          Icon={AutoModeRounded}
          className={"iconLoading"}
          sx={{ fontSize: 32 }}
        />
        <Box mr={2} />
        <Typography>
          Analyzing
          <span className="loadingStyle"></span>
        </Typography>
      </Box>
    </Box>
  );
}

export default MessageLoader;
