import React, { useState } from "react";
import { Tooltip, IconButton, useTheme, CircularProgress } from "@mui/material";
import { BookmarkBorderRounded, BookmarkRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { bookmarkOrUnmarkMessage } from "../../../../core/repo/sessionRepo";
import { useSnackbar } from "notistack";

function Bookmark({ message }) {
  const { answer, query } = message;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isBookmarked = query["is_bookmarked"];
  const [isLoading, setIsLoading] = useState(false);

  const handleClickBookmark = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        bookmarkOrUnmarkMessage({
          query: {
            ...query,
            is_bookmarked: !isBookmarked,
          },
          answer: { ...answer, is_bookmarked: !isBookmarked },
        })
      );
      enqueueSnackbar(`${isBookmarked ? "Unmarked" : "Bookmarked"} message`);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <Tooltip title="Bookmark">
      {isLoading ? (
        <CircularProgress
          size={18}
          sx={{ color: theme.palette.action.active, marginLeft: 1 }}
        />
      ) : (
        <IconButton
          size="small"
          sx={{ color: theme.palette.action.active, marginLeft: 1 }}
          onClick={handleClickBookmark}>
          {isBookmarked ? (
            <BookmarkRounded fontSize="small" sx={{ fontSize: 18 }} />
          ) : (
            <BookmarkBorderRounded fontSize="small" sx={{ fontSize: 18 }} />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
}

export default Bookmark;
