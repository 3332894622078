export const GET_DOMAINS_INITIATE = "@files/get-domains-initiate";
export const GET_DOMAINS_SUCCESS = "@files/get-domains-success";
export const GET_DOMAINS_FAILURE = "@files/get-domains-failure";
export const SET_SELECTED_DOMAIN_SUCCESS =
  "@session/set_selected_domain_success";

export function getDomainsInitiate() {
  return {
    type: GET_DOMAINS_INITIATE,
  };
}

export function getDomainsSuccess(domains) {
  return {
    type: GET_DOMAINS_SUCCESS,
    payload: {
      domains,
    },
  };
}

export function getDomainsFailure() {
  return {
    type: GET_DOMAINS_FAILURE,
  };
}

export function setSelectedDomainSuccess(selectedDomain) {
  return {
    payload: { selectedDomain },
    type: SET_SELECTED_DOMAIN_SUCCESS,
  };
}
