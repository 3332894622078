import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Divider,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIosRounded, MoreVert, BorderColor, Delete } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { Q_A_MODE_URL, SESSIONS_URL } from "../../../utils/routes";
import { usePostHog } from "posthog-js/react";
import { GO_BACK_TO_SESSIONS } from "../../../utils/posthogEvents";
import { resetSessionMessagesSuccess } from "../../../core/events/sessionEvents";
import EditSession from "../components/EditSession";
import DeleteSession from "../components/DeleteSession";
import { DynamicIcon } from "../../../components/CustomIcons";
import { toTitleCase } from "../../../utils/utils";

function Header({ session }) {
  const posthog = usePostHog();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedDomain, areDomainsFetched } = useSelector((state) => state.domain);

  const handleBackToSessions = () => {
    history.push({
      pathname: Q_A_MODE_URL,
      state: location.state,
    });
    dispatch(resetSessionMessagesSuccess());
    posthog.capture(GO_BACK_TO_SESSIONS);
  };

  return (
    areDomainsFetched && (
      <>
        <Box
          px={{ xs: 2, sm: 3 }}
          py={1.5}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Breadcrumbs>
            <Link
              color={"textSecondary"}
              variant="subtitle1"
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              component={Box}
              onClick={handleBackToSessions}>
              <ArrowBackIosRounded fontSize="small" sx={{ mr: 0.5 }} />
              QnA
            </Link>
            <Typography
              variant="subtitle1"
              color={"textPrimary"}
              noWrap
              maxWidth={400}>
              {session["title"] === "~New Conversation"
                ? "New Session"
                : session["title"]}
            </Typography>
          </Breadcrumbs>
          <SessionActionsMenu session={session} />
          {/* <Box width={"100%"} display={"flex"} alignItems={"center"}>
            <IconButton edge="start" onClick={handleBackToSessions}>
              <ArrowBackIosRounded fontSize="small" />
            </IconButton>
            <Box
              width={"calc(100% - 36px)"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} alignItems={"center"}>
                {session["domain_id"] && (
                  <>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      mr={1}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleBackToSessions()}
                    >
                      <DynamicIcon
                        iconName={selectedDomain["icon"]}
                        sx={{
                          fontSize: 24,
                          color: theme.palette.text.secondary,
                        }}
                      />
                      <Box mr={1} />
                      <Typography
                        variant="subtitle1"
                        color={"textSecondary"}
                        sx={{
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {toTitleCase(selectedDomain["domain"])} Sessions
                      </Typography>
                      <Box mr={1} />
                      <Typography>/</Typography>
                    </Box>
                  </>
                )}
                <Typography variant="subtitle1" noWrap maxWidth={400}>
                  {session["title"] === "~New Conversation" ? "New Session" : session["title"]}
                </Typography>
              </Box>
              <SessionActionsMenu session={session} />
            </Box>
          </Box> */}
        </Box>
        {/* <Divider /> */}
      </>
    )
  );
}

export function SessionActionsMenu({ session }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton edge="end" onClick={handleOpen}>
        <MoreVert />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
      >
        <EditSession session={session}>
          <MenuItem>
            <ListItemIcon>
              <BorderColor />
            </ListItemIcon>
            <ListItemText>Rename Session</ListItemText>
          </MenuItem>
        </EditSession>
        <DeleteSession sessionId={session["id"]}>
          <MenuItem>
            <ListItemIcon>
              <Delete sx={{ color: theme.palette.error.main }} />
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.error.main }}>Delete Session</ListItemText>
          </MenuItem>
        </DeleteSession>
      </Menu>
    </>
  );
}

export default Header;
