import domainService from "../api/domainService";
import {
  getDomainsFailure,
  getDomainsSuccess,
  getDomainsInitiate,
  setSelectedDomainSuccess,
} from "../events/domainEvents";
import { getSelectedDomainLocalStorage } from "../storage/localStorage";

export function getDomains() {
  return async (dispatch) => {
    try {
      dispatch(getDomainsInitiate());
      const response = await domainService.getDomains();
      const domains = response["domains"] || [];
      dispatch(getDomainsSuccess(domains));
      const selectedDomainFromLocalStorage = getSelectedDomainLocalStorage();
      let selectedDomain = selectedDomainFromLocalStorage;
      if (!selectedDomainFromLocalStorage && domains.length > 0) {
        selectedDomain = domains[0];
      }
      dispatch(setSelectedDomainSuccess(selectedDomain));
      return domains;
    } catch (error) {
      dispatch(getDomainsFailure());
      throw error;
    }
  };
}
