import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Box } from "@mui/material";
import Loader from "../../../components/Loader";
import { yellow } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  highlight: {
    background: yellow[100],
  },
  headerCell: {
    background: theme.palette.background.light,
    borderRadius: "0px !important",
  },
}));

function CSVViewer({ file, highlightRows = [], highlightColumns = [] }) {
  const { classes } = useStyles();
  const { file_url } = file;
  const [data, setData] = useState({ columns: [], rows: [] });
  const { rows, columns } = data;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCSV = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(file_url);
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let result = "";
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          result += decoder.decode(value, { stream: true });
          done = readerDone;
        }

        // Parse the CSV text
        Papa.parse(result, {
          header: true, // Ensure the CSV has headers to map to DataGrid columns
          skipEmptyLines: true,
          complete: (parsedResult) => {
            const keys = Object.keys(parsedResult.data[0]).filter(
              (key) => key !== "__uid"
            );

            // Dynamically generate columns from CSV headers
            const generatedColumns = keys
              .filter(
                (key) =>
                  highlightColumns.length === 0 ||
                  (highlightColumns.length === 1 &&
                    highlightColumns[0] === "*") ||
                  highlightColumns.includes(key.trim().toLowerCase())
              )
              .map((key) => ({
                headerClassName: classes.headerCell,
                field: key,
                headerName: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the header names
                minWidth: 150,
                // cellClassName: (params) => {
                //   return highlightRows.length > 0 &&
                //     highlightRows.includes(params.row.__uid.toString()) &&
                //     (highlightColumns.length === 0 ||
                //       (highlightColumns.length === 1 &&
                //         highlightColumns[0] === "*") ||
                //       highlightColumns.includes(key.trim().toLowerCase()))
                //     ? classes.highlight
                //     : "";
                // },
              }));

            // Add an ID field to each row (required for DataGrid)
            const transformedData = parsedResult.data
              .filter(
                (row) =>
                  highlightRows.length === 0 ||
                  highlightRows.includes(row.__uid.toString())
              )
              .map((row, index) => ({
                id: index + 1,
                ...row,
              }));

            setData({ columns: generatedColumns, rows: transformedData });
          },
        });
      } catch (error) {
        console.error("Error fetching the CSV file:", error);
      }
      setIsLoading(false);
    };

    fetchCSV();
  }, [file_url]);

  return (
    <Box
      bgcolor={"white"}
      width={"100%"}
      height={"100%"}
      overflow={"scroll"}
      sx={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}>
      {isLoading && <Loader />}
      <DataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        isCellEditable={false}
        sx={{
          border: "none",
          borderRadius: 0,
        }}
      />
    </Box>
  );
}

export default CSVViewer;
