import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  MenuItem,
  ListItemIcon,
  Tooltip,
  DialogActions,
  Button,
} from "@mui/material";
import {
  CloseRounded,
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
  AddRounded,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  resetBtn: {
    "&.Mui-disabled": {
      background: "none",
    },
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function DataAnalysisFileDialog({
  handleCreateSession,
  excelFilesInSelectedDomain,
}) {
  const { classes } = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const handleSelectFile = (file) => {
    setSelectedFile(file);
  };

  const handleApplyFilters = () => {
    handleCreateSession(selectedFile);
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddRounded />}
        onClick={handleOpen}>
        New Session
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Typography variant="h6">Ask within specific files</Typography>
          <IconButton edge="end" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          {excelFilesInSelectedDomain.map((file) => {
            const { id, name } = file;
            const isSelected = selectedFile && id === selectedFile["id"];
            return (
              <MenuItem
                key={id}
                sx={{ paddingX: 0 }}
                onClick={() => handleSelectFile(file)}>
                <ListItemIcon>
                  {isSelected ? (
                    <RadioButtonCheckedRounded />
                  ) : (
                    <RadioButtonUncheckedRounded />
                  )}
                </ListItemIcon>
                <Tooltip title={name}>
                  <Typography variant="body2" noWrap textOverflow={"ellipsis"}>
                    {name}
                  </Typography>
                </Tooltip>
              </MenuItem>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.resetBtn}
            variant="text"
            onClick={handleClose}>
            <span className={classes.gradientText}>Cancel</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedFile}
            onClick={handleApplyFilters}>
            Start Session
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DataAnalysisFileDialog;
