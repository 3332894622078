import React from "react";
import { Box } from "@mui/material";
import SessionsList from "./SessionsList/SessionsList";

function Results({ sessions, handleSessionClick }) {
  return (
    <Box pb={6}>
      <SessionsList
        sessions={sessions}
        handleSessionClick={handleSessionClick}
      />
    </Box>
  );
}

export default Results;
