import React from "react";
import { Box } from "@mui/material";
import { Timeline } from "@mui/lab";
import CustomTimlineItem from "../../../components/CustomTimlineItem";
import Sources from "../components/Sources";
import Debug from "../components/Debug";
import Feedback from "../components/Feedback/Feedback";
import MainQuestion from "./MainQuestion";
import Question from "../components/Message/Question";
import Answer from "../components/Message/Answer";

function Results({
  source = null,
  message,
  messages,
  messageRefs,
  queryContainerHeight,
  handleSourceChange,
  handleEntityChange,
  isStreaming,
}) {
  return (
    <Box>
      <MainQuestion message={message} />
      <Timeline>
        {messages.map((message, i) => {
          return (
            <FollowUpMessage
              key={i}
              source={source}
              messageRefs={messageRefs}
              queryContainerHeight={queryContainerHeight}
              message={message}
              handleSourceChange={handleSourceChange}
              handleEntityChange={handleEntityChange}
              isStreaming={isStreaming}
              isLastMessage={i === messages.length - 1}
            />
          );
        })}
      </Timeline>
    </Box>
  );
}

function FollowUpMessage({
  source,
  message,
  messageRefs,
  queryContainerHeight,
  handleSourceChange,
  handleEntityChange,
  isStreaming,
  isLastMessage,
}) {
  const { query, answer } = message;

  return (
    <CustomTimlineItem>
      <Box
        sx={{
          minHeight:
            isLastMessage && !isStreaming
              ? `calc(100vh - ${282 + queryContainerHeight}px)`
              : "inherit",
        }}
        mb={6}
        ref={(el) => (messageRefs.current[query["message_id"]] = el)}>
        <Question query={query} isFollowup />
        <Sources
          source={source}
          answer={answer}
          handleSourceChange={handleSourceChange}
        />
        <Answer answer={answer} handleEntityChange={handleEntityChange} />
        <Box mt={5} display={"flex"} alignItems={"center"}>
          <Feedback message={message} />
          <Debug query={query} />
        </Box>
      </Box>
    </CustomTimlineItem>
  );
}

export default Results;
