import { produce } from "immer";
import {
  DELETE_FILE_SUCCESS,
  GET_FILES_SUCCESS,
  GET_FILES_FAILURE,
  GET_FILES_INITIATE,
  UPDATE_FILE_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  CLEAR_FILES,
  GET_DOMAINS_INITIATE,
  GET_DOMAINS_SUCCESS,
  GET_DOMAINS_FAILURE,
} from "../events/filesEvents";

const initialState = {
  files: [],
  completedFiles: [],
  loading: false,
  isFetched: false,
};

const filesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILES_INITIATE: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.isFetched = false;
      });
    }

    case GET_FILES_SUCCESS: {
      const { files } = action.payload;
      return produce(state, (draft) => {
        draft.files = files;
        if (files) {
          draft.completedFiles = files.filter(
            (file) => file["status"] === "completed"
          );
        }
        draft.loading = false;
        draft.isFetched = true;
      });
    }

    case GET_FILES_FAILURE: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case UPDATE_FILE_SUCCESS: {
      const { file } = action.payload;
      return produce(state, (draft) => {
        const files = draft.files.map((f) => {
          if (f["id"] === file["id"]) {
            return { ...f, name: file["name"] };
          }
          return f;
        });
        draft.files = files;
        if (files) {
          draft.completedFiles = files.filter(
            (file) => file["status"] === "completed"
          );
        }
      });
    }

    case DELETE_FILE_SUCCESS: {
      const { fileId } = action.payload;
      return produce(state, (draft) => {
        const files = draft.files.filter((file) => fileId !== file["id"]);
        draft.files = files;
        if (files) {
          draft.completedFiles = files.filter(
            (file) => file["status"] === "completed"
          );
        }
      });
    }

    case UPLOAD_FILE_SUCCESS: {
      const { file } = action.payload;
      const tempFile = {
        ...file,
        domain_object: {
          domain: "others",
          icon: "NoteRounded",
          color: "grey",
        },
      };
      return produce(state, (draft) => {
        draft.files = [tempFile, ...draft.files];
        if (draft.files) {
          draft.completedFiles = draft.files.filter(
            (file) => file["status"] === "completed"
          );
        }
      });
    }

    case CLEAR_FILES: {
      return produce(state, (draft) => {
        draft.files = [];
        draft.completedFiles = [];
      });
    }

    default: {
      return state;
    }
  }
};

export default filesReducer;
