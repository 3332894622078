import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  Chip,
  IconButton,
  Button,
} from "@mui/material";
import {
  ChevronRightRounded,
  FiberManualRecord,
  ShortcutRounded,
} from "@mui/icons-material";
import moment from "moment";
import { makeStyles } from "tss-react/mui";
import Debug from "../components/Debug";
import { GradientIconAccent } from "../../../components/GradientIcon";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Sources from "../components/Sources";
import Feedback from "../components/Feedback/Feedback";
import Question from "../components/Message/Question";
import Answer from "../components/Message/Answer";
import ShareMessage from "../components/Message/ShareMessage";
import Usage from "../components/Message/Usage";
import AnalysisLogs from "../DataAnalysis/Messages/AnalysisLogs";
import Bookmark from "../components/Message/Bookmark";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  label: {
    paddingLeft: 8,
  },
}));

function getDate(date) {
  date = moment(date);
  const today = moment();
  const yesterday = moment().subtract(1, "day");
  if (date.isSame(today, "day")) {
    return { part1: "Today", part2: date.format("h:mm A") };
  } else if (date.isSame(yesterday, "day")) {
    return { part1: "Yesterday", part2: date.format("h:mm A") };
  }

  return {
    part1: date.format("DD MMM YYYY"),
    part2: date.format("h:mm A"),
  };
}

function Message({
  source,
  message,
  messagesRef,
  messageRefs,
  handleSourceChange,
  handleEntityChange,
  isLastMessage,
  isLastGroup,
  isStreaming,
  queryContainerHeight,
}) {
  const history = useHistory();
  const location = useLocation();
  const { sessionId } = useParams();
  const { query, answer } = message;
  const numFollowUp = query["threads"] ? query["threads"].length : 0;

  const handleClickViewFollowup = (scroll = true) => {
    handleNavigate(!scroll ? { noScroll: true } : {});
  };

  const handleClickViewFollowupMessage = (id) => {
    handleNavigate({ messageId: id });
  };

  const handleClickElaborate = () => {
    handleNavigate({ elaborate: true });
  };

  const handleNavigate = (searchObj) => {
    const scrollPosition = messagesRef.current
      ? messagesRef.current.scrollTop
      : 0;
    history.push({
      pathname: `/qna/sessions/${sessionId}/messages/${query["message_id"]}`,
      search: "?" + new URLSearchParams(searchObj).toString(),
      state: { scrollPosition, ...(location.state || {}) },
    });
  };

  const isQna = !answer["content_object"]["type"];
  const isDataAnalysis = answer["content_object"]["type"] === "tabular_data";

  return (
    <Box
      pt={5}
      ref={(el) => (messageRefs.current[query["message_id"]] = el)}
      sx={{
        minHeight:
          isLastMessage && isLastGroup && !isStreaming
            ? `calc(100vh - ${(!isQna ? 200 : 208) + queryContainerHeight}px)`
            : "inherit",
      }}>
      <Question query={query} />
      {isDataAnalysis ? (
        <AnalysisLogs answer={answer} />
      ) : (
        <Sources
          source={source}
          answer={answer}
          handleSourceChange={handleSourceChange}
        />
      )}
      <Answer
        answer={answer}
        source={source}
        handleEntityChange={handleEntityChange}
        handleSourceChange={handleSourceChange}
      />
      {!answer["error"] && (
        <Box mt={3} pl={6}>
          <FollowupGlimpse
            query={query}
            handleClickViewFollowup={handleClickViewFollowup}
            handleClickViewFollowupMessage={handleClickViewFollowupMessage}
          />
          <Box
            mt={2.5}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ md: "center", xs: "start" }}
            justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"}>
              <Feedback message={message} />
              <ShareMessage message={message} />
              <Debug query={query} />
              {/* <Bookmark message={message} /> */}
              <Usage isLastMessage={isLastMessage && isLastGroup} />
            </Box>
            {isQna && (
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ md: "center", xs: "start" }}>
                {numFollowUp === 0 && answer["content_object"]["answer_found"] && (
                  <>
                    <FollowupChip
                      label={"Ask to Elaborate"}
                      onClick={handleClickElaborate}
                    />
                    <Box mr={{ md: 1.5, xs: 0 }} mb={{ xs: 2, md: 0 }} />
                  </>
                )}
                <FollowupChip
                  label={
                    numFollowUp > 0
                      ? `${numFollowUp} Follow-Up${numFollowUp > 1 ? "s" : ""}`
                      : "Ask a follow-up question"
                  }
                  onClick={handleClickViewFollowup}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      {!(isLastGroup && isLastMessage) && <Box mb={6} />}
      {!isLastMessage && <Divider />}
    </Box>
  );
}

export function FollowupGlimpse({
  query,
  handleClickViewFollowup,
  handleClickViewFollowupMessage,
}) {
  const theme = useTheme();
  const { classes } = useStyles();
  const numFollowUp = query["threads"] ? query["threads"].length : 0;

  function getBorderRadius(index) {
    if (numFollowUp === 1) {
      return "12px";
    }
    if (index === 0) {
      return "12px 12px 0px 0px";
    }
    if (index === 1) {
      return "0px 0px 12px 12px";
    }
    return 0;
  }

  return (
    numFollowUp > 0 && (
      <Box width={"100%"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={1}>
          <Box display={"flex"} alignItems={"center"}>
            <ShortcutRounded
              sx={{
                transform: "scaleX(-1) rotate(180deg)",
                color: theme.palette.action.disabled,
              }}
            />
            <Box mr={1} />
            <Typography variant="subtitle2" color="textSecondary">
              Follow-Ups
            </Typography>
          </Box>
          <Button
            onClick={() => handleClickViewFollowup(false)}
            variant="text"
            sx={{
              textTransform: "none",
              borderRadius: 24,
              paddingX: 1.5,
            }}
            endIcon={
              <GradientIconAccent
                Icon={ChevronRightRounded}
                sx={{ fontSize: 20 }}
              />
            }>
            <span
              className={
                classes.gradientText
              }>{`View All (${numFollowUp})`}</span>
          </Button>
        </Box>
        <Box bgcolor={"#F5F5F5"} borderRadius={3}>
          {query["threads"]
            .slice(0, 2)
            .sort(
              (msg1, msg2) =>
                Date.parse(msg2["query"]["created_at"]) -
                Date.parse(msg1["query"]["created_at"])
            )
            .map((threadMessage, i) => {
              const { query } = threadMessage;

              return (
                <Fragment key={i}>
                  <Box
                    px={1.5}
                    py={1}
                    sx={{
                      cursor: "pointer",
                      borderRadius: getBorderRadius(i),
                      ":hover": {
                        background: theme.palette.action.hover,
                      },
                    }}
                    onClick={() =>
                      handleClickViewFollowupMessage(query["message_id"])
                    }>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}>
                      <Box width={"calc(100% - 56px)"}>
                        <Typography
                          variant="body2"
                          noWrap
                          sx={{ marginBottom: 0.5 }}>
                          <span
                            style={{
                              color: theme.palette.text.secondary,
                              marginRight: 4,
                              fontWeight: 500,
                            }}>
                            Q.
                          </span>
                          {query["content_object"]["text"]}
                        </Typography>
                        <Typography
                          display={"flex"}
                          alignItems={"center"}
                          variant="caption"
                          color="textSecondary"
                          sx={{ marginLeft: "18px" }}>
                          {getDate(query["created_at"])["part1"]}
                          <FiberManualRecord
                            sx={{
                              fontSize: 4,
                              color: theme.palette.text.secondary,
                              marginX: 0.5,
                            }}
                          />
                          {getDate(query["created_at"])["part2"]}
                        </Typography>
                      </Box>
                      <Box mr={3} />
                      <IconButton edge="end">
                        <ChevronRightRounded fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  {i === 0 && numFollowUp >= 2 && <Divider sx={{ ml: 1.5 }} />}
                </Fragment>
              );
            })}
        </Box>
      </Box>
    )
  );
}

export function FollowupChip({ label, onClick }) {
  const { classes } = useStyles();
  return (
    <Chip
      classes={{ label: classes.label }}
      label={
        <Typography variant="subtitle2" className={classes.gradientText}>
          {label}
        </Typography>
      }
      variant="outlined"
      color="secondary"
      icon={
        <GradientIconAccent
          Icon={ShortcutRounded}
          sx={{ transform: "scaleX(-1) rotate(180deg)" }}
        />
      }
      sx={{
        borderRadius: 6,
        borderTopLeftRadius: 0,
        paddingLeft: 1.5,
        borderColor: "#BF40AE",
      }}
      onClick={onClick}
    />
  );
}

export default Message;
