import React, { useEffect, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import GridView from "./GridView";
import ListView from "./ListView";
import Filters from "../../Filters";
import {
  getFileGridViewLocalStorage,
  setFileGridViewLocalStorage,
} from "../../../../../core/storage/localStorage";
import { makeStyles } from "tss-react/mui";

const sortOptions = [
  { value: "name", label: "Name" },
  { value: "created_at", label: "Last Uploaded" },
  { value: "updated_at", label: "Last Modified" },
];

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    fontWeight: 500,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function FilesList({
  files,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const [query, setQuery] = useState();
  const [filteredFiles, setFilteredFiles] = useState(files);
  const [sortOption, setSortOption] = useState(sortOptions[1].value);
  const [isGridView, setIsGridView] = useState(getFileGridViewLocalStorage());

  useEffect(() => {
    const sortedFiles = getSortedFiles(files, sortOption);
    setFilteredFiles(sortedFiles);
  }, [files]);

  function handleSearchFiles(query) {
    setQuery(query);
    let filteredFiles = [];
    if (!query) {
      filteredFiles = files;
    } else {
      query = query.toLowerCase();
      filteredFiles = files.filter((file) =>
        file["name"].toLowerCase().includes(query)
      );
    }
    const sortedFiles = getSortedFiles(filteredFiles, sortOption);
    setFilteredFiles(sortedFiles);
  }

  function handleSortFiles(sortOption) {
    setSortOption(sortOption);
    const sortedFiles = getSortedFiles(filteredFiles, sortOption);
    setFilteredFiles(sortedFiles);
  }

  function getSortedFiles(files, sortOption) {
    const processingFiles = [],
      processedFiles = [];

    files.forEach((file) => {
      if (file["status"] === "completed") {
        processedFiles.push(file);
      } else {
        processingFiles.push(file);
      }
    });
    const sortedFiles = [...files].sort((f1, f2) => {
      switch (sortOption) {
        case "name": {
          return f1["name"].localeCompare(f2["name"]);
        }
        case "created_at": {
          return Date.parse(f2["created_at"]) - Date.parse(f1["created_at"]);
        }
        case "updated_at": {
          return Date.parse(f2["updated_at"]) - Date.parse(f1["updated_at"]);
        }
      }
    });
    return sortedFiles;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Filters
        query={query}
        sortOption={sortOption}
        sortOptions={sortOptions}
        isGridView={isGridView}
        handleSearchFiles={handleSearchFiles}
        handleSortFiles={handleSortFiles}
        handleViewChange={(value) => {
          setIsGridView(value);
          setFileGridViewLocalStorage(value);
        }}
      />
      <Box display="flex" alignItems="center" mt={3} mb={2}>
        <Typography fontWeight={500}>Total</Typography>
        <Chip
          label={`${filteredFiles.length} file${
            filteredFiles.length > 1 ? "s" : ""
          }`}
          size="small"
          sx={{ ml: 1 }}
        />
      </Box>
      {isGridView ? (
        <GridView
          files={filteredFiles}
          handleSelectFile={handleSelectFile}
          handleDeleteFile={handleDeleteFile}
          handleUpdateFile={handleUpdateFile}
        />
      ) : (
        <ListView
          files={filteredFiles}
          handleSelectFile={handleSelectFile}
          handleDeleteFile={handleDeleteFile}
          handleUpdateFile={handleUpdateFile}
        />
      )}
    </Box>
  );
}

export default FilesList;
