import React, { useState } from "react";
import {
  IconButton,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import EditFileDialog from "../EditFileDialog";
import {
  MoreVertRounded,
  EditRounded,
  DeleteRounded,
  OpenInNewRounded,
  DownloadRounded,
} from "@mui/icons-material";
import { notSupportedBrowserFiles } from "./GridView";

function FileActionsMenu({
  file,
  edge = "end",
  handleDeleteFile,
  handleUpdateFile,
  handleOpenFileInNewTab,
}) {
  const { id, file_type } = file;
  const theme = useTheme();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        edge="end"
        sx={edge === "end" ? { marginRight: -1.5, marginTop: -0.5 } : {}}>
        <MoreVertRounded fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        autoFocus={false}
        disableAutoFocusItem
        slotProps={{ paper: { sx: { borderRadius: 2 } } }}>
        {notSupportedBrowserFiles.includes(file_type) ? (
          <MenuItem onClick={handleOpenFileInNewTab}>
            <ListItemIcon>
              <DownloadRounded />
            </ListItemIcon>
            <Typography>Download and open locally</Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleOpenFileInNewTab}>
            <ListItemIcon>
              <OpenInNewRounded />
            </ListItemIcon>
            <Typography>Open File in New Tab</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={() => setIsEditDialogOpen(true)}>
          <ListItemIcon>
            <EditRounded />
          </ListItemIcon>
          <Typography>Rename File</Typography>
        </MenuItem>
        <MenuItem
          sx={{ color: theme.palette.error.main }}
          onClick={() => setIsDeleteDialogOpen(true)}>
          <ListItemIcon>
            <DeleteRounded sx={{ color: theme.palette.error.main }} />
          </ListItemIcon>
          <Typography>Delete File</Typography>
        </MenuItem>
      </Menu>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title={"Delete File"}
          content={`Do you really want to delete this file? This will permanently delete it from the account.`}
          noLabel={"cancel"}
          yesLabel={"yes, delete"}
          onNo={() => setIsDeleteDialogOpen(false)}
          onYes={() => handleDeleteFile(id)}
          isDelete={true}
        />
      )}
      {isEditDialogOpen && (
        <EditFileDialog
          file={file}
          handleUpdateFile={handleUpdateFile}
          onClose={() => setIsEditDialogOpen(false)}
        />
      )}
    </>
  );
}

export default FileActionsMenu;
