import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSessionMessages } from "../../../core/repo/sessionRepo";
import { getSessionMessagesSuccess } from "../../../core/events/sessionEvents";
import { formQuestionAnswerPairs } from "../Messages";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import { Box } from "@mui/material";
import Header from "./Header";
import Loader from "../../../components/Loader";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Q_A_MODE_URL, SESSIONS_URL } from "../../../utils/routes";
import MessagesComponent from "../components/MessagesComponent";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
  },
}));

function Followup() {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { sessionId, messageId } = useParams();
  const { messages, isMessagesLoading } = useSelector((state) => state.session);
  const message = messages.find((m) => m["query"]["message_id"] === messageId);
  const threadMessages = message ? message["query"]["threads"] || [] : [];

  async function fetchData() {
    try {
      let tempMessages = messages;
      if (messages.length === 0) {
        const response = await dispatch(getSessionMessages(sessionId));
        const processedMessages = formQuestionAnswerPairs(
          response["messages"] || []
        );
        tempMessages = processedMessages;
        dispatch(getSessionMessagesSuccess(processedMessages));
      }
      const isFollowupExists = checkIfFollowupExists(tempMessages);
      if (!isFollowupExists) {
        enqueueSnackbar("You don't have access to this page.", {
          variant: "error",
        });
        history.push({ pathname: Q_A_MODE_URL });
        return;
      }
    } catch (e) {
      enqueueSnackbar("You don't have access to this page.", {
        variant: "error",
      });
      history.push({ pathname: Q_A_MODE_URL });
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function checkIfFollowupExists(messages) {
    if (messages.length === 0) {
      return true;
    }
    const message = messages.find(
      (m) => m["query"]["message_id"] === messageId
    );
    return message ? true : false;
  }

  return (
    <Page className={classes.root} title={"Followup"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        {isMessagesLoading && <Loader />}
        {message && <Header message={message} />}
        <MessagesComponent
          message={message}
          messages={threadMessages}
          session={{ id: sessionId }}
        />
      </Box>
    </Page>
  );
}

export default Followup;
