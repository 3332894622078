import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  InputBase,
  useTheme,
  IconButton,
  Tooltip,
  useMediaQuery,
  Divider,
  alpha,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { KeyboardVoice, SendRounded } from "@mui/icons-material";
import { Audio } from "./Audio";
import { enqueueSnackbar } from "notistack";
import FilesListDialogAnchor from "./FilesListDialog";

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    margin: 0,
    borderRadius: 16,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  audioContainer: {
    margin: 0,
  },
  activeSendBtn: {
    borderRadius: 8,
    cursor: "pointer",
    marginLeft: 12,
    background: theme.palette.light,
  },
  disabledSendBtn: {
    borderRadius: 8,
    marginLeft: 12,
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  micIcon: {
    color: theme.palette.primary.main, // Change color as needed
    fontSize: "1.5rem", // Adjust size as needed
  },
  container: {
    padding: 12,
    paddingBottom: 8,
    borderRadius: 16,
    border: `1px solid ${theme.palette.divider}`,
    background: "#FAFAFA",
    "&:focus-within": {
      border: `2px solid ${theme.palette.accent}`,
      margin: -1,
    },
  },
  focusedContainer: {
    border: `2px solid ${theme.palette.accent}`,
  },
}));

export const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const Query = forwardRef(
  (
    { handleStreamMessages, isStreaming = false, mode = "qna", queryRef },
    ref
  ) => {
    const queryInputRef = useRef();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down("sm"));
    const { classes } = useStyles();
    const [query, setQuery] = useState("");
    const [audioMode, setAudioMode] = useState(false);
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

    useImperativeHandle(ref, () => ({
      query: query,
      setQuery: handleQueryChange,
    }));

    async function handleQuerySubmit() {
      const queryValue = query.trim();
      if (queryValue) {
        handleQueryChange("");
        const response = await handleStreamMessages(queryValue);
        if (response === "stream_api_error") {
          handleQueryChange(queryValue);
          setIsSendButtonDisabled(false);
        }
      }
    }

    const handleQueryChange = (value) => {
      setQuery(value);
      setTimeout(() => {
        if (queryInputRef.current && value && !query) {
          const inputElement = queryInputRef.current;
          inputElement.focus();
          inputElement.setSelectionRange(value.length, value.length);
        }
      }, 0);
      if (value.trim() === "") {
        if (!isSendButtonDisabled) {
          setIsSendButtonDisabled(true);
        }
      } else {
        if (isSendButtonDisabled) {
          setIsSendButtonDisabled(false);
        }
      }
    };

    function handleOnKeyDown(e) {
      const { key, shiftKey } = e;
      if (key === "Enter" && !shiftKey && query && !isXS && !isStreaming) {
        e.preventDefault();
        handleQuerySubmit();
      }
    }

    return (
      <>
        <Box ref={queryRef} className={`${classes.container}`}>
          {audioMode ? (
            <Box className={classes.audioContainer}>
              <Audio
                recordingError={(e) => {
                  setAudioMode(false);
                  enqueueSnackbar("Permission denied", { variant: "error" });
                }}
                recordingCancelled={(e) => {
                  setAudioMode(false);
                  enqueueSnackbar("Voice query cancelled", {
                    variant: "warning",
                  });
                }}
                recordingText={(text) => {
                  setAudioMode(false);
                  if (text) {
                    handleQueryChange(text["text"]);
                  } else {
                    enqueueSnackbar("Error processing voice query", {
                      variant: "error",
                    });
                  }
                }}
              />
            </Box>
          ) : (
            <InputBase
              fullWidth
              value={query}
              onChange={(e) => handleQueryChange(e.target.value)}
              onKeyDown={handleOnKeyDown}
              multiline
              minRows={2}
              maxRows={6}
              inputRef={queryInputRef}
              autoFocus
              placeholder={"Ask anything..."}
            />
          )}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"} ml={1}>
              {mode !== "data_analysis" && (
                <>
                  <FilesListDialogAnchor />
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ height: 32, alignSelf: "center", marginX: 0.5 }}
                  />
                </>
              )}
              <IconButton
                edge={mode === "data_analysis" ? "start" : null}
                onClick={(e) => setAudioMode(true)}
                disabled={audioMode}>
                <Tooltip title="Speak your question">
                  <KeyboardVoice />
                </Tooltip>
              </IconButton>
            </Box>
            <IconButton
              onClick={handleQuerySubmit}
              disabled={!(query && !isStreaming)}
              className={
                query && !isStreaming
                  ? classes.activeSendBtn
                  : classes.disabledSendBtn
              }>
              <SendRounded
                sx={{
                  color: !query || isStreaming ? "inherit" : "#ffffff",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </>
    );
  }
);
export default Query;
