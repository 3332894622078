import React from "react";
import {
  Box,
  Breadcrumbs,
  Divider,
  Link,
  Typography,
  Tooltip,
  Chip,
  useTheme,
} from "@mui/material";
import { SessionActionsMenu } from "../../Messages/Header";
import {
  ArrowBackIosRounded,
  ChevronRightOutlined,
  ChevronRightRounded,
  ExpandLessRounded,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { DATA_ANALYSIS_MODE_URL } from "../../../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { getSessionMessagesSuccess } from "../../../../core/events/sessionEvents";
import { getFileIconImage } from "../../../Files/FilesView/Results/FilesList/GridView";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  deleteIcon: {
    color: `${theme.palette.text.secondary} !important`,
  },
}));

function Header({ session, source, handleSourceChange }) {
  const { classes } = useStyles();
  const { completedFiles } = useSelector((state) => state.files);
  const file = completedFiles.find((f) => f["id"] === session["file_id"]);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBack = () => {
    dispatch(getSessionMessagesSuccess([]));
    history.push(DATA_ANALYSIS_MODE_URL);
  };

  const isMainFileSource = source && source["mainFile"];

  return (
    <>
      <Box
        px={{ xs: 2, sm: 3 }}
        py={1.5}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Breadcrumbs>
          <Link
            color={"textSecondary"}
            variant="subtitle1"
            underline="hover"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            component={Box}
            onClick={handleBack}>
            <ArrowBackIosRounded fontSize="small" sx={{ mr: 0.5 }} />
            Data Analysis
          </Link>
          <Typography
            variant="subtitle1"
            color={"textPrimary"}
            noWrap
            maxWidth={400}>
            {session["title"] === "~New Conversation"
              ? "New Session"
              : session["title"]}
          </Typography>
        </Breadcrumbs>
        <Box display={"flex"} alignItems={"center"}>
          {file && (
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="caption" color={"textSecondary"}>
                Main File:
              </Typography>
              <Tooltip title={file["name"]} arrow>
                <Chip
                  classes={{ deleteIcon: classes.deleteIcon }}
                  sx={{
                    maxWidth: 300,
                    mx: 1,
                    background: isMainFileSource ? "#E8EAF6" : "null",
                  }}
                  clickable
                  onClick={() =>
                    handleSourceChange({
                      section: file["name"],
                      csv_file_map: file["csv_file_map"],
                      mainFile: true,
                    })
                  }
                  label={
                    <Typography variant="caption" noWrap>
                      {file["name"]}
                    </Typography>
                  }
                  icon={
                    <img
                      src={getFileIconImage(file["file_type"])}
                      alt={file["name"]}
                      width={16}
                      height={16}
                    />
                  }
                  onDelete={() =>
                    handleSourceChange({
                      section: file["name"],
                      csv_file_map: file["csv_file_map"],
                      mainFile: true,
                    })
                  }
                  deleteIcon={
                    isMainFileSource ? (
                      <ExpandLessRounded />
                    ) : (
                      <ChevronRightRounded />
                    )
                  }
                />
              </Tooltip>
            </Box>
          )}
          <SessionActionsMenu session={session} />
        </Box>
      </Box>
      {/* <Divider /> */}
    </>
  );
}

export default Header;
