import { produce } from "immer";
import {
  GET_DOMAINS_INITIATE,
  GET_DOMAINS_SUCCESS,
  GET_DOMAINS_FAILURE,
  SET_SELECTED_DOMAIN_SUCCESS,
} from "../events/domainEvents";
import { setSelectedDomainLocalStorage } from "../storage/localStorage";

const initialState = {
  selectedDomain: null,
  domains: [],
  areDomainsLoading: false,
  areDomainsFetched: false,
};

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOMAINS_INITIATE: {
      return produce(state, (draft) => {
        draft.areDomainsLoading = true;
        draft.areDomainsFetched = false;
      });
    }

    case GET_DOMAINS_SUCCESS: {
      const { domains } = action.payload;
      return produce(state, (draft) => {
        draft.domains = [...domains].sort((a, b) =>
          a.domain.localeCompare(b.domain)
        );
        draft.areDomainsFetched = true;
        draft.areDomainsLoading = false;
      });
    }

    case GET_DOMAINS_FAILURE: {
      return produce(state, (draft) => {
        draft.areDomainsLoading = false;
      });
    }

    case SET_SELECTED_DOMAIN_SUCCESS: {
      const { selectedDomain } = action.payload;
      return produce(state, (draft) => {
        draft.selectedDomain = selectedDomain;
        setSelectedDomainLocalStorage(selectedDomain);
      });
    }

    default: {
      return state;
    }
  }
};

export default domainReducer;
